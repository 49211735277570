import { observer } from 'mobx-react-lite';
import { useContext, type PropsWithChildren, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '../../../store/AppContext.ts';
import { AccountModel } from '../../../types/account.types.ts';
import Flex, { Gap, Justify } from '../../atoms/Flex/Flex.tsx';
import LanguageSelection from '../../atoms/LanguageSelection/LanguageSelection.tsx';
import css from './Card.module.scss';

interface CardProps {
    className?: string;
    account?: AccountModel;
    unbound?: boolean;
}
function Card({
    children,
    className,
    account,
    unbound = false,
}: PropsWithChildren<CardProps>): ReactElement {
    const { t } = useTranslation();
    const { LanguageStore } = useContext(AppContext);
    // if the component returns the select
    // this means there are languages to render and we want to space them out
    // if it returns nothing, we want to space the powered by text to the end
    const flexSpacing =
        LanguageStore.availableLanguages.length > 1 ? Justify.SPACE_BETWEEN : Justify.END;

    return (
        <div className={`${css.wrapper} ${unbound ? css.unbound : css.bound}`}>
            {account?.logo && (
                <img className={css.accountLogo} src={account.logo} crossOrigin="anonymous" />
            )}
            <div className={`${css.card} ${className} ${account?.theme ? css.hasTheme : ''}`}>
                <div className={css.main}>{children}</div>

                <Flex className={css.footer} justify={flexSpacing} gap={Gap.XS}>
                    {LanguageStore.availableLanguages.length > 1 && <LanguageSelection />}
                    <Flex gap={Gap.XS}>
                        <span>{t('common:poweredBy')} Openvolt</span>
                        <img alt="logo" className={css.logo} src="/logo.svg" />
                    </Flex>
                </Flex>
            </div>
        </div>
    );
}

export default observer(Card);
