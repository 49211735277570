import { createContext } from 'react';
import ConsentStore from './ConsentStore.ts';
import LanguageStore from './LanguageStore.ts';

const stores = {
    ConsentStore,
    LanguageStore,
};

const AppContext = createContext(stores);
export default AppContext;
