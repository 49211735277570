import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { useContext, useState, type ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import API from '../../../../../../api/API.ts';
import AppContext from '../../../../../../store/AppContext.ts';
import { ConsentMethod, HostedConsentModel } from '../../../../../../types/hosted-consent.types.ts';
import { ScreenTransition } from '../../../../../animations/ScreenTransition/ScreenTransition.tsx';
import Button from '../../../../../atoms/Button/Button.tsx';
import CollapsibleNotice from '../../../../../atoms/CollapsibleNotice/CollapsibleNotice.tsx';
import { ErrorNotice } from '../../../../../atoms/ErrorNotice/ErrorNotice.tsx';
import Flex, { Align, FlexDirection, Gap } from '../../../../../atoms/Flex/Flex.tsx';
import Heading from '../../../../../atoms/Heading/Heading.tsx';
import Card from '../../../../../molecules/Card/Card.tsx';
import ConsentSteps from '../../../../../molecules/ConsentSteps/ConsentSteps.tsx';
import WizardWithSteps, {
    WizardStep,
} from '../../../../../molecules/WizardWithSteps/WizardWithSteps.tsx';
import { FlowSteps } from '../../../es.commercial.specific.tsx';
import css from './DatadisConnection.module.scss';
import hasAccount1 from './assets/hasAccount1.png';
import hasAccount2 from './assets/hasAccount2.png';
import hasAccount3 from './assets/hasAccount3.png';
import noAccount1 from './assets/noAccount1.png';
import noAccount2 from './assets/noAccount2.png';
import noAccount3 from './assets/noAccount3.png';
import noAccount4 from './assets/noAccount4.png';
import Notice from '../../../../../atoms/Notice/Notice.tsx';

export enum Steps {
    ACCOUNT_CONFIRMATION = 'account_confirmation',
    NO_ACCOUNT = 'no_account',
    ACCOUNT_PRESENT = 'account_present',
}

interface ComponentProps {
    consent: HostedConsentModel;
    onNext: () => void;
}

function DatadisConnection({ consent, onNext }: ComponentProps): ReactElement {
    // #region State
    const [error, setError] = useState<null | string>(null);
    const [isReturningUser] = useState(Boolean(consent.contextual_data?.pending_account_creation));
    const [step, setStep] = useState(
        isReturningUser ? Steps.NO_ACCOUNT : Steps.ACCOUNT_CONFIRMATION,
    );
    const [loading, setLoading] = useState(false);
    const { t } = useTranslation('es-flow');
    const { ConsentStore } = useContext(AppContext);
    // #endregion

    // #region Steps
    const noAccountSteps = [
        {
            value: 'visit_datadis',
            children: (
                <div className={`${css.accountStep} ${css.hasAccountStep}`}>
                    <span>
                        {t('common.visit')}{' '}
                        <a href="https://datadis.es" target="_blank">
                            {t('common.datadis')}
                        </a>{' '}
                        <Trans
                            t={t}
                            i18nKey={
                                'es-flow:commercialFlow:connectStep.noAccount.visitDatadis'
                            }></Trans>
                    </span>
                    <img src={noAccount1} />
                </div>
            ),
            btnPreviousDisabled: false,
            btnPreviousHandler: async () => {
                setStep(Steps.ACCOUNT_CONFIRMATION);
            },
            btnPreviousText: t('common:back'),
        },
        {
            value: 'choose_category',
            children: (
                <div className={`${css.accountStep} ${css.hasAccountStep}`}>
                    <Trans
                        t={t}
                        i18nKey={'es-flow:commercialFlow:connectStep.noAccount.typeChoice'}>
                        Choose <strong>Organizations</strong>.
                    </Trans>
                    <img src={noAccount2} />
                </div>
            ),
        },
        {
            value: 'complete_profile',
            children: (
                <div className={`${css.accountStep} ${css.hasAccountStep}`}>
                    <span>{t('es-flow:commercialFlow:connectStep.noAccount.completeAccount')}</span>
                    <p>
                        <Trans
                            t={t}
                            i18nKey={
                                'es-flow:commercialFlow:connectStep.noAccount.completeAccountSubtext'
                            }>
                            The registration <strong>must</strong> be done by the individual on the
                            energy supplier's contract.
                        </Trans>
                    </p>
                    <img src={noAccount3} />
                </div>
            ),
            btnNextHandler: async (_prev, next) => {
                // Before we leave the screen, we say the user is in the process of creating an account
                // so we can skip the account creation step when they return
                await API.updateConsent(consent.id, {
                    contextual_data: {
                        ...consent.contextual_data,
                        pending_account_creation: true,
                    },
                });
                // ConsentStore.setConsent(res.data.data);
                next();
            },
        },
        {
            value: 'validate_account',
            btnPreviousDisabled: isReturningUser,
            children: isReturningUser ? (
                <div className={`${css.accountStep} ${css.hasAccountStep}`}>
                    <p>
                        {t('es-flow:commercialFlow:connectStep.noAccount.returningUser.paragraph1')}
                    </p>
                    <p>
                        {t('es-flow:commercialFlow:connectStep.noAccount.returningUser.paragraph2')}
                    </p>
                </div>
            ) : (
                <div className={`${css.accountStep} ${css.hasAccountStep}`}>
                    <span>{t('es-flow:commercialFlow:connectStep.noAccount.validateNIF')}</span>
                    <p>{t('es-flow:commercialFlow:connectStep.noAccount.validateNIFStep2')}</p>
                    <p>
                        <Notice>
                            {t('es-flow:commercialFlow:connectStep.noAccount.validateNIFNotice')}
                        </Notice>
                    </p>
                    <p>{t('es-flow:commercialFlow:connectStep.noAccount.returnHere')}</p>
                    <img src={noAccount4} />
                </div>
            ),

            btnNextText: t('es-flow:commercialFlow:connectStep.noAccount.myAccountIsActive'),
            btnNextDisabled: false,
            btnNextHandler: async () => {
                setStep(Steps.ACCOUNT_PRESENT);
            },
        },
    ] as const satisfies readonly WizardStep[];

    const hasAccountSteps = [
        {
            value: 'visit_datadis',
            children: (
                <div className={`${css.accountStep} ${css.hasAccountStep}`}>
                    <span>
                        {t('common.visit')}{' '}
                        <a href="https://datadis.es" target="_blank">
                            {t('common.datadis')}
                        </a>{' '}
                        <Trans
                            t={t}
                            i18nKey={
                                'es-flow:commercialFlow:connectStep.accountPresent.visitDatadis'
                            }></Trans>
                    </span>
                    <img src={hasAccount1} />
                </div>
            ),
            btnPreviousDisabled: isReturningUser,
            btnPreviousHandler: async () => {
                setStep(Steps.ACCOUNT_CONFIRMATION);
            },
            btnPreviousText: t('common:back'),
        },
        {
            value: 'authorize_user',
            children: (
                <div className={`${css.accountStep} ${css.hasAccountStep}`}>
                    <Trans
                        t={t}
                        i18nKey={'es-flow:commercialFlow:connectStep.accountPresent.authorizeUser'}>
                        Navigate to <strong>My Supplies</strong> and select{' '}
                        <strong>Authorized User</strong>. Then click on{' '}
                        <strong>+ Authorize new user</strong>.
                    </Trans>
                    <img src={hasAccount2} />
                </div>
            ),
        },
        {
            value: 'provide_consent',
            children: (
                <div className={`${css.accountStep} ${css.hasAccountStep}`}>
                    <span>Provide Consent.</span>
                    <ol className={css.stepList}>
                        <li className={css.stepListItem}>
                            {t(
                                'es-flow:commercialFlow:connectStep.accountPresent.provideConsent.step1',
                            )}
                            <strong className={css.nif}>
                                {' '}
                                N0286268H
                                <DocumentDuplicateIcon
                                    onClick={async () => {
                                        await navigator.clipboard.writeText('N0286268H');
                                    }}
                                    className={css.icon}
                                />
                            </strong>
                        </li>
                        <li className={css.stepListItem}>
                            {t(
                                'es-flow:commercialFlow:connectStep.accountPresent.provideConsent.step2',
                            )}
                        </li>
                        <li className={css.stepListItem}>
                            {t(
                                'es-flow:commercialFlow:connectStep.accountPresent.provideConsent.step3',
                            )}
                        </li>
                        <li className={css.stepListItem}>
                            <Trans
                                t={t}
                                i18nKey={
                                    'es-flow:commercialFlow:connectStep.accountPresent.provideConsent.step4'
                                }>
                                Click <strong>Send Authorization</strong>
                            </Trans>
                        </li>
                    </ol>
                    <img src={hasAccount3} />
                </div>
            ),
            btnNextText: loading
                ? t('common:verifying')
                : t('es-flow:commercialFlow:connectStep.accountPresent.authorizationSent'),
            btnNextDisabled: !!loading,
            btnNextHandler: async () => {
                setLoading(true);
                const res = await API.updateConsent(consent.id, {
                    consent_method: ConsentMethod.DIGITAL_CONSENT,
                    contextual_data: {
                        ...consent.contextual_data,
                        pending_account_creation: false,
                        has_datadis_account: true,
                    },
                });
                ConsentStore.setConsent(res.data.data);
                await API.approveConsent(consent.id);
                setLoading(false);
                onNext();
            },
        },
    ] as const satisfies readonly WizardStep[];
    // #endregion

    // #region Handlers
    async function setContextualData(step: Steps): Promise<void> {
        const result = await API.updateConsent(consent.id, {
            contextual_data: {
                ...consent.contextual_data,
                had_datadis_account: step === Steps.ACCOUNT_PRESENT,
            },
        });

        if (result.status >= 400) {
            setError(result.data);
            return;
        }

        ConsentStore.setConsent({
            ...consent,
            contextual_data: {
                had_datadis_account: step === Steps.ACCOUNT_PRESENT,
            },
        });

        setStep(step);
    }

    // #endregion

    function renderStep(): ReactElement {
        switch (step) {
            case Steps.ACCOUNT_CONFIRMATION:
                return (
                    <Flex
                        className={css.datadisConnection}
                        gap={Gap.MD}
                        flexDirection={FlexDirection.COLUMN}
                        align={Align.STRETCH}>
                        <Heading>
                            {t('es-flow:commercialFlow:connectStep.accountConfirm.heading')}
                        </Heading>
                        {error != null && <ErrorNotice errors={error} />}
                        <Flex gap={Gap.SM} className={css.infoBox}>
                            <Button
                                outline={true}
                                fullWidth={false}
                                onClick={async () => {
                                    await setContextualData(Steps.ACCOUNT_PRESENT);
                                }}>
                                {t(
                                    'es-flow:commercialFlow:connectStep.accountConfirm.btnHasAccount',
                                )}
                            </Button>
                            <Button
                                fullWidth={false}
                                onClick={async () => {
                                    await setContextualData(Steps.NO_ACCOUNT);
                                }}>
                                {t(
                                    'es-flow:commercialFlow:connectStep.accountConfirm.btnNoAccount',
                                )}
                            </Button>
                        </Flex>
                        <CollapsibleNotice
                            title={t('es-flow:commercialFlow:connectStep.accountConfirm.readMore')}
                            type="info">
                            <Trans
                                t={t}
                                i18nKey={
                                    'es-flow:commercialFlow:connectStep.accountConfirm.tooltip'
                                }
                            />
                        </CollapsibleNotice>
                    </Flex>
                );
            case Steps.NO_ACCOUNT:
                return (
                    <WizardWithSteps<typeof noAccountSteps>
                        heading={
                            isReturningUser
                                ? t(
                                      'es-flow:commercialFlow:connectStep.noAccount.returningUser.heading',
                                  )
                                : t('es-flow:commercialFlow:connectStep.noAccount.heading')
                        }
                        steps={noAccountSteps}
                        startStep={
                            consent.contextual_data?.pending_account_creation
                                ? 'validate_account'
                                : 'visit_datadis'
                        }
                    />
                );
            case Steps.ACCOUNT_PRESENT:
                return (
                    <WizardWithSteps<typeof hasAccountSteps>
                        heading={t('es-flow:commercialFlow:connectStep.accountPresent.heading')}
                        steps={hasAccountSteps}
                    />
                );
            default:
                return <></>;
        }
    }

    return (
        <Card account={consent.account}>
            <Flex gap={Gap.LG} flexDirection={FlexDirection.COLUMN} align={Align.STRETCH}>
                <ConsentSteps steps={FlowSteps} current={FlowSteps[1]} />
                <ScreenTransition screen={step}>{renderStep()}</ScreenTransition>
            </Flex>
        </Card>
    );
}

export default DatadisConnection;
