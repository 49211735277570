import { type ReactElement } from 'react';
import FadeIn from '../../animations/FadeIn/FadeIn.tsx';
import Button from '../../atoms/Button/Button.tsx';
import Heading from '../../atoms/Heading/Heading.tsx';
import css from './NotSupposedToBeHere.module.scss';

interface Props {
    clickAction?: () => void;
}
export default function NotSupposedToBeHere({ clickAction }: Props): ReactElement {
    return (
        <div className={css.applicationError}>
            <FadeIn>
                <Heading>This is awkward but...</Heading>
                <p>
                    It looks like something caused the flow to break. We have automatically logged
                    this error and you don't need to report it.
                </p>
                <p>You can retry using the button below.</p>
                <Button
                    onClick={
                        clickAction ??
                        (() => {
                            window.location.reload();
                        })
                    }>
                    Reload
                </Button>
            </FadeIn>
        </div>
    );
}
