import { DocumentIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { observer } from 'mobx-react-lite';
import React, { useContext, useEffect, useState, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../api/API.ts';
import AppContext from '../../../store/AppContext.ts';
import { FileMetadata } from '../../../types/common.types.ts';
import { ConsentEvidenceType, HostedConsentModel } from '../../../types/hosted-consent.types.ts';
import base64ToDataURL from '../../../utils/base64ToDataURL.ts';
import { calculateFileSize } from '../../../utils/calculateFileSize.ts';
import FadeIn from '../../animations/FadeIn/FadeIn.tsx';
import Flex, { Align, Gap, Justify } from '../../atoms/Flex/Flex.tsx';
import Loading from '../../atoms/Loading/Loading.tsx';
import Muted from '../../atoms/Muted/Muted.tsx';
import css from './EvidenceUpload.module.scss';

const imageFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];

const allowedFileTypes = [
    ...imageFileTypes,
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

function isAllowed(file: File): boolean {
    return allowedFileTypes.includes(file.type);
}

interface EvidenceUploadProps {
    consent: HostedConsentModel;
    evidenceType?: ConsentEvidenceType;
    extraMetadata?: HostedConsentEvidenceMetadata['extra_metadata'];
    onFileSet?: (fileMetadata: HostedConsentEvidenceMetadata | null) => void;
    onError?: (error: string | null) => void;
}
type HostedConsentEvidenceMetadata = Omit<
    FileMetadata<{
        ocr_status: {
            manual: boolean;
        };
    }>,
    'review_status'
>;

function EvidenceUpload({
    consent,
    evidenceType = ConsentEvidenceType.NOT_SPECIFIED,
    extraMetadata = undefined,
    onError,
    onFileSet,
}: EvidenceUploadProps): ReactElement {
    const [dragging, setDragging] = useState(false);

    const [file, setFile] = useState<HostedConsentEvidenceMetadata | null>(null);
    const [filePreview, setFilePreview] = useState<string | null>(null);
    const [error, setError] = useState<string | null>(null);
    const { t } = useTranslation();

    const [loading, setLoading] = useState(false);

    const { ConsentStore } = useContext(AppContext);
    const handleError = (error: string | null): void => {
        if (typeof onError === 'function') onError(error);
        setError(error);
    };
    const handleFile = (metadata: HostedConsentEvidenceMetadata | null): void => {
        if (onFileSet) onFileSet(metadata);
        setFile(metadata);
    };
    async function getConsentEvidence(): Promise<void> {
        setLoading(true);
        await API.axios
            .get(`${import.meta.env.VITE_API}/hosted-consents/hcf/${consent.id}`)
            .then(res => {
                if (res.data.evidence_document_metadata) {
                    ConsentStore.setEvidenceDocumentMetadata(res.data.evidence_document_metadata);
                    handleFile(res.data.evidence_document_metadata);

                    void API.axios
                        .get(
                            `${import.meta.env.VITE_API}/hosted-consents/hcf/${
                                consent.id
                            }/evidences`,
                        )
                        .then(res => {
                            if (res.data.data != null) {
                                ConsentStore.setEvidenceDocument(res.data.data);
                                setFilePreview(res.data.data);
                            }
                        });
                } else {
                    ConsentStore.setEvidenceDocument(null);
                    ConsentStore.setEvidenceDocumentMetadata(null);
                    handleFile(null);
                    setFilePreview(null);
                }
            })
            .finally(() => {
                setLoading(false);
            });
    }

    useEffect(() => {
        getConsentEvidence().catch(error => {
            handleError(error);
        });
    }, []);

    async function uploadEvidence(
        file: string,
        metadata: HostedConsentEvidenceMetadata,
    ): Promise<void> {
        setLoading(true);
        await API.axios.put(
            `${import.meta.env.VITE_API}/hosted-consents/hcf/${consent.id}/evidences`,
            {
                evidence_document: file,
                evidence_document_metadata: metadata,
            },
        );
        await getConsentEvidence();
    }

    /**
     * Handles the "dragover" event.
     *
     * @param {React.DragEvent} event - The drag event object.
     * @return {void}
     */
    function handleDragOver(event: React.DragEvent): void {
        event.preventDefault();
        setDragging(true);
    }

    /**
     * Handles the drag leave event.
     *
     * @param {React.DragEvent} event - The drag leave event object.
     * @return {void}
     */
    function handleDragLeave(event: React.DragEvent): void {
        event.preventDefault();
        setDragging(false);
    }

    /**
     * Checks if the given file is valid and processes it.
     *
     * @param {File} newFile - The file to be checked.
     * @return {void}
     */
    function checkFile(newFile: File): void {
        if (newFile.size > 10000000) {
            // That's 10MB in normal human speech
            handleError(t('validation:evidenceUploadComponent.LOATooLarge'));
            return;
        }
        if (!isAllowed(newFile)) {
            handleError(t('validation:evidenceUploadComponent.LOAInvalidFileType'));
            return;
        }
        const metadata = {
            name: newFile.name,
            file_type: newFile.type,
            evidence_type: evidenceType,
            size: newFile.size,
            extra_metadata: extraMetadata,
        };
        handleFile(metadata);
        handleError(null);

        const reader = new FileReader();

        reader.onloadend = () => {
            const result = reader.result as string;
            const b64 = result.replace(/^data:?[A-z]*\/?[A-z]*;base64,/, '');
            setFilePreview(b64);
            uploadEvidence(b64, metadata)
                .catch(error => {
                    onError && onError(error);
                    handleError(error);
                })
                .finally(() => {
                    onFileSet && onFileSet(metadata);
                    setLoading(false);
                });
        };

        reader.onerror = () => {
            const error = t('validation:evidenceUploadComponent.fileReadingError');
            console.error(error);
            onError && onError(error);
        };

        reader.readAsDataURL(newFile);
    }

    /**
     * Handle file selection event.
     *
     * @param {React.ChangeEvent<HTMLInputElement>} event - The file selection event.
     * @return {void}
     */
    function onFileSelect(event: React.ChangeEvent<HTMLInputElement>): void {
        if (event.target.files != null) {
            const newFile = event.target.files[0];
            checkFile(newFile);
        }
    }

    async function handleRemoveFile(): Promise<void> {
        setLoading(true);
        await API.removeEvidences(consent.id);
        handleFile(null);
        setFilePreview(null);
        handleError(null);
        setLoading(false);
        await getConsentEvidence();
    }

    /**
     * Handles the drop event and fetches the dropped files.
     *
     * @param {React.DragEvent} event - The drop event object.
     * @return {void}
     */
    function handleDrop(event: React.DragEvent): void {
        // Fetch the files
        const droppedFiles = Array.from(event.dataTransfer.files);
        const newFile = droppedFiles[0];
        if (newFile != null) {
            event.preventDefault();
            setDragging(false);
            checkFile(newFile);
        }
    }

    return (
        <div
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            className={`
                ${dragging ? css.dragging : ''}
                ${css.evidenceUpload}
                ${error != null ? css.error : ''}
                ${file != null ? css.success : ''}
            `}>
            {loading ? (
                <Loading />
            ) : (
                <>
                    {error != null ? (
                        <>
                            <FadeIn>
                                <Muted>{error}</Muted>
                            </FadeIn>
                        </>
                    ) : (
                        <>
                            {file == null && (
                                <>
                                    <p>{t('common:evidenceUploadComponent.dragAndDrop')}</p>
                                    <Muted className={css.small}>
                                        {t('common:evidenceUploadComponent.fileTypes')}
                                    </Muted>
                                </>
                            )}
                            {file != null && (
                                <Flex
                                    className={css.file}
                                    justify={Justify.SPACE_BETWEEN}
                                    gap={Gap.MD}
                                    align={Align.STRETCH}>
                                    <div className={css.fileInfo}>
                                        <strong>{file.name}</strong>
                                        <Muted>{calculateFileSize(file.size)}</Muted>
                                    </div>
                                    {filePreview != null && (
                                        <div className={css.filePreview}>
                                            {imageFileTypes.includes(file.file_type) ? (
                                                <img
                                                    src={base64ToDataURL(
                                                        filePreview,
                                                        file.file_type,
                                                    )}
                                                    alt="File Preview"
                                                />
                                            ) : (
                                                <DocumentIcon className={css.icon} />
                                            )}
                                        </div>
                                    )}
                                    <XMarkIcon className={css.delete} onClick={handleRemoveFile} />
                                </Flex>
                            )}
                        </>
                    )}
                    <input type="file" className={css.fileInput} onChange={onFileSelect} />
                </>
            )}
        </div>
    );
}

export default observer(EvidenceUpload);
