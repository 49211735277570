import { useState, type ReactElement } from 'react';
import css from './WizardWithSteps.module.scss';
import Flex, { Align, FlexDirection, Gap } from '../../atoms/Flex/Flex.tsx';
import Small from '../../atoms/Small/Small.tsx';
import { useTranslation } from 'react-i18next';
import Heading from '../../atoms/Heading/Heading.tsx';
import React from 'react';
import Muted from '../../atoms/Muted/Muted.tsx';
import Button from '../../atoms/Button/Button.tsx';
import { ScreenTransition } from '../../animations/ScreenTransition/ScreenTransition.tsx';

/**
 * In order for this to work properly (so you have intellisense completion for the steps),
 * You need to define your step array as const like this:
 *
 * ```ts
 * const steps = [
        {
            value: 'mystep',
            children: (<></>),
        }
    ] as const;
    ```

    And then pass it to the WizardWithSteps component like this:
    ```tsx
    <WizardWithSteps<typeof steps>
        steps={steps}
        heading="Heading"
        subheading="Subheading"
        startStep="mystep" />
    ```

    This will allow you to pass `startStep` with the correct union types of the steps. But if you don't need to pass `startStep` you can just pass the steps array without the `as const` and the `typeof steps` in the WizardWithSteps component. And it will be made as a string by default
 */
export interface WizardStep<AvailableSteps extends string = string> {
    readonly children: ReactElement[] | ReactElement;
    readonly value: AvailableSteps;
    readonly btnNextText?: string;
    readonly btnNextHandler?: (prev: () => void, next: () => void) => Promise<void>;
    readonly btnNextDisabled?: boolean;
    readonly btnPreviousText?: string;
    readonly btnPreviousHandler?: (prev: () => void, next: () => void) => Promise<void>;
    readonly btnPreviousDisabled?: boolean;
}

interface ComponentProps<AvailableSteps extends string> {
    heading: string;
    subheading?: string;
    steps: Readonly<Array<WizardStep<AvailableSteps>>>;
    startStep?: AvailableSteps;
}

function WizardWithSteps<const T extends Readonly<WizardStep[]>>({
    steps,
    heading,
    subheading,
    startStep,
}: ComponentProps<T[number]['value']>): ReactElement {
    const { t } = useTranslation();
    const [currentIndex, setCurrentIndex] = useState<number>(
        startStep ? steps.findIndex(step => step.value === startStep) : 0,
    );

    const nextStep = (): void => {
        setCurrentIndex(Math.min(steps.length - 1, currentIndex + 1));
    };

    const previousStep = (): void => {
        setCurrentIndex(Math.max(0, currentIndex - 1));
    };

    function isBtnFunctionPresent(fn: unknown): fn is (...args: any[]) => any {
        return !!fn && typeof fn === 'function';
    }

    return (
        <div className={css.wizardSteps}>
            <div className={css.headerWrapper}>
                <Heading>{heading}</Heading>

                {/* subheading */}
                {subheading && <Muted>{subheading}</Muted>}
            </div>

            {/* Step children elements */}
            <div className={css.childrenElements}>
                <ScreenTransition screen={steps[currentIndex].value}>
                    {steps[currentIndex].children}
                </ScreenTransition>
            </div>

            {/* Buttons */}
            <Flex gap={Gap.MD} className={css.buttonWrapper}>
                <Button
                    fullWidth={false}
                    onClick={async () => {
                        const btnFunction = steps[currentIndex]?.btnPreviousHandler;
                        if (isBtnFunctionPresent(btnFunction)) {
                            await btnFunction(previousStep, nextStep);
                            return;
                        }
                        previousStep();
                    }}
                    disabled={steps[currentIndex].btnPreviousDisabled ?? currentIndex === 0}
                    outline={true}>
                    {steps[currentIndex].btnPreviousText ?? t('common:previous')}
                </Button>
                <Button
                    fullWidth={false}
                    disabled={
                        steps[currentIndex].btnNextDisabled ?? currentIndex === steps.length - 1
                    }
                    onClick={async () => {
                        const btnFunction = steps[currentIndex]?.btnNextHandler;
                        if (isBtnFunctionPresent(btnFunction)) {
                            await btnFunction(previousStep, nextStep);
                            return;
                        }
                        nextStep();
                    }}>
                    {steps[currentIndex].btnNextText ?? t('common:next')}
                </Button>
            </Flex>

            {/* step counter */}
            <Flex className={css.stepWrapper} gap={Gap.NONE}>
                {steps.map((step, index) => {
                    return (
                        <React.Fragment key={index}>
                            <Flex
                                key={index}
                                data-index={index}
                                data-key={step.value}
                                className={`${css.step} ${
                                    currentIndex === index ? css.current : ''
                                } ${index < currentIndex ? css.completed : ''}`}
                                flexDirection={FlexDirection.COLUMN}
                                align={Align.CENTER}>
                                <Small>{index + 1}</Small>
                            </Flex>
                            <span className={css.line}></span>
                        </React.Fragment>
                    );
                })}
            </Flex>
        </div>
    );
}

export default WizardWithSteps;
