import { type ReactElement } from 'react';
import css from './PrivacyText.module.scss';
import { useTranslation } from 'react-i18next';

function PrivacyText(): ReactElement {
    const { t } = useTranslation('common');
    return (
        <span className={css.privacyText}>
            Openvolt{' '}
            <a href="https://www.openvolt.com/privacy-policy" target="_blank">
                {t('privacyPolicy')}
            </a>
        </span>
    );
}

export default PrivacyText;
