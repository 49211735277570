import Color from 'color';
import { observer } from 'mobx-react-lite';
import { useContext, useEffect, type ReactElement } from 'react';
import AppContext from '../../../store/AppContext.ts';
import { ConsentFlowType, HostedConsentModel } from '../../../types/hosted-consent.types.ts';
import GBCommercialBlanket from '../../flows/gb.commercial.blanket/gb.commercial.blanket.tsx';
import GBResidentialSpecific from '../../flows/gb.residential.specific/gb.residential.specific.tsx';
import NlCommercialSpecific from '../../flows/nl.commercial.specific/nl.commercial.specific.tsx';
import NlResidentialSpecific from '../../flows/nl.residential.specific/nl.residential.specific.tsx';
import ConsentFlowGuard from '../../organisms/ConsentFlowGuard/ConsentFlowGuard.tsx';
import EsResidentialSpecific from '../../flows/es.residential.specific/es.residential.specific.tsx';
import EsCommercialSpecific from '../../flows/es.commercial.specific/es.commercial.specific.tsx';
import FrResidentialSpecific from '../../flows/fr.residential.specific/fr.residential.specific.tsx';
import FrCommercialSpecific from '../../flows/fr.commercial.specific/fr.commercial.specific.tsx';

export enum ConsentFlowScreens {
    START = 'start',
    BLANKET_DESCRIPTION = 'blanket_description',
    CONSENT_METHOD = 'consent_method',
    LOA_CONSENT = 'loa_submission',
    DIGITAL_CONSENT = 'digital_consent',
    THIRD_PARTY_CONSENT = 'third_party_consent',
    EMAIL_VERIFICATION = 'email_verification',
    COMPLETED = 'completed',
}

function ConsentFlow(): ReactElement {
    const { ConsentStore } = useContext(AppContext);
    const consent = ConsentStore.consent as HostedConsentModel<any, never>;

    function renderScreen(): ReactElement | null {
        if (consent != null) {
            switch (consent.flow_type) {
                case ConsentFlowType.UK_COMMERCIAL_BLANKET:
                    return (
                        <GBCommercialBlanket
                            consent={consent}
                            evidenceDocument={ConsentStore.evidenceDocument}
                            termsChecked={ConsentStore.termsChecked}
                        />
                    );
                case ConsentFlowType.UK_RESIDENTIAL_SPECIFIC:
                    return <GBResidentialSpecific consent={consent} />;
                case ConsentFlowType.NL_COMMERCIAL_SPECIFIC:
                    return <NlCommercialSpecific consent={consent} />;
                case ConsentFlowType.NL_RESIDENTIAL_SPECIFIC:
                    return <NlResidentialSpecific />;
                case ConsentFlowType.ES_RESIDENTIAL_SPECIFIC:
                    return <EsResidentialSpecific consent={consent} />;
                case ConsentFlowType.ES_COMMERCIAL_SPECIFIC:
                    return <EsCommercialSpecific consent={consent} />;
                case ConsentFlowType.FR_RESIDENTIAL_SPECIFIC:
                    return <FrResidentialSpecific consent={consent} />;
                case ConsentFlowType.FR_COMMERCIAL_SPECIFIC:
                    return <FrCommercialSpecific consent={consent} />;
            }
        }
        return null;
    }

    useEffect(() => {
        if (consent?.account) {
            const account = consent?.account;
            const theme = account?.theme ? account.theme : null;
            const root = document.documentElement;
            if (theme) {
                root?.style.setProperty('--button-color', theme);
                root?.style.setProperty('--theme', theme);
                const color = Color(theme);
                if (color.isLight()) {
                    root?.style.setProperty('--button-text', '#111827');
                }
            }
        }
    }, [consent?.account]);

    return <ConsentFlowGuard>{renderScreen()}</ConsentFlowGuard>;
}

export default observer(ConsentFlow);
