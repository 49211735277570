import { CpuChipIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { ReactElement, useEffect, useState } from 'react';
import { DataFetching } from '../../../../../animations/DataFetching/DataFetching.tsx';
import FadeIn from '../../../../../animations/FadeIn/FadeIn.tsx';
import { AddressBox } from '../../../../../atoms/AddressBox/AddressBox.tsx';
import Button from '../../../../../atoms/Button/Button.tsx';
import Flex, { Align, FlexDirection, Gap, Justify } from '../../../../../atoms/Flex/Flex.tsx';
import Heading from '../../../../../atoms/Heading/Heading.tsx';
import Muted from '../../../../../atoms/Muted/Muted.tsx';
import { EANCollectionSteps, NLPostcodeSearchResult } from '../EANCollection/EANCollection.tsx';
import css from './SearchForEAN.module.scss';
import API from '../../../../../../api/API.ts';
import { useTranslation } from 'react-i18next';
import { HostedConsentModel } from '../../../../../../types/hosted-consent.types.ts';

interface ComponentProps {
    consent: HostedConsentModel;
    address: NLPostcodeSearchResult | null;
    onChangeAddress: () => void;
    onSelectEAN: (foundEANs: string[], selectedEANs: string[]) => void;
    setStep: (step: EANCollectionSteps) => void;
}

interface EANDataAPIResponse {
    success: boolean;
    data: {
        meters: Array<{
            ean: string;
            address: {
                postalCode: string;
                streetNumber: number;
                street: string;
                city: string;
            };
            product: 'ELK' | 'GAS' | 'H2';
            organisation: string;
            gridOperatorEan: string;
            specialMeteringPoint: boolean;
            gridArea: string;
            bagId: string;
        }>;
    };
}

function SearchForEAN({
    address,
    consent,
    onChangeAddress,
    onSelectEAN,
    setStep,
}: ComponentProps): ReactElement {
    const { t } = useTranslation('nl-flow');
    const [loading, setLoading] = useState(true);
    const [EANData, setEANData] = useState<null | EANDataAPIResponse['data']>(null);
    const [selectedEAN, setSelectedEAN] = useState<string[]>([]);

    useEffect(() => {
        if (address) {
            setLoading(true);
            if (!address.house_number || !address.postcode) {
                return;
            }

            const buildingNumberAddition = address.house_number_addition
                ? `,${address.house_number_addition}`
                : '';
            const queryString = new URLSearchParams({
                data_source: 'edsn',
                address_identifier: `${address.house_number}${buildingNumberAddition}`,
                postcode: address.postcode,
            });
            API.axios
                .get<EANDataAPIResponse>(
                    `${import.meta.env.VITE_API}/hosted-consents/hcf/${
                        consent.id
                    }/postcode-meter-lookup?${queryString.toString()}`,
                    {
                        headers: {
                            'x-consent-token': consent.token,
                        },
                    },
                )
                .then(({ data }) => {
                    // this is duplicated because axios adds the response in a data object
                    // and we also return the response in the data object
                    const response = data.data;
                    if (response.meters.length > 0) {
                        setEANData(response);
                        setSelectedEAN(response.meters.map(mp => mp.ean));
                        setLoading(false);
                        return;
                    }
                    throw new Error(t('validation:general.noMetersFound'));
                })
                .catch(err => {
                    console.warn(t('nl-flow:errors.dataFetchError', { error: err }));
                    setLoading(false);
                    setEANData(null);
                });
        }
    }, [address]);

    if (loading) {
        return <DataFetching text={t('nl-flow:common.searchingEanCode')} />;
    }
    if (!address) {
        throw new Error('Finished loading but no address selected in SearchForEAN.tsx');
    }

    return (
        <Flex
            flexDirection={FlexDirection.COLUMN}
            align={Align.STRETCH}
            gap={Gap.LG}
            className={css.searchForEAN}>
            <AddressBox
                address={`${t('common:postcode')}: ${address.postcode}, ${t('common:building')}: ${
                    address.house_number
                } ${address.house_number_addition ?? ''}`}
                onChange={
                    EANData
                        ? () => {
                              setStep(EANCollectionSteps.ADDRESS_COLLECTION);
                          }
                        : undefined
                }
            />
            {EANData?.meters && EANData.meters.length > 0 ? (
                <>
                    <div>
                        <Heading>{t('nl-flow:searchForEanStep.foundHeading')}</Heading>
                        <Muted>{t('nl-flow:searchForEanStep.foundNotice')}</Muted>
                    </div>
                    <FadeIn>
                        {EANData.meters.map(mp => {
                            return (
                                <Flex
                                    key={mp.ean}
                                    className={
                                        selectedEAN.includes(mp.ean)
                                            ? `${css.ean} ${css.meterBlock}`
                                            : `${css.deletedEan} ${css.meterBlock}`
                                    }
                                    justify={Justify.SPACE_BETWEEN}
                                    gap={Gap.MD}>
                                    <CpuChipIcon className={css.icon} />
                                    <span style={{ width: '80%' }}>{mp.ean}</span>
                                    {selectedEAN.includes(mp.ean) ? (
                                        <TrashIcon
                                            className={`${css.icon} ${css.clickable}`}
                                            onClick={e => {
                                                e.stopPropagation();
                                                setSelectedEAN(
                                                    selectedEAN.filter(ean => ean !== mp.ean),
                                                );
                                            }}
                                        />
                                    ) : (
                                        <PlusCircleIcon
                                            className={`${css.icon} ${css.clickable}`}
                                            onClick={e => {
                                                e.stopPropagation();
                                                setSelectedEAN([...selectedEAN, mp.ean]);
                                            }}
                                        />
                                    )}
                                </Flex>
                            );
                        })}
                    </FadeIn>
                    <Button
                        className={css.selectEAN}
                        disabled={selectedEAN.length === 0}
                        onClick={() => {
                            onSelectEAN(
                                EANData.meters.map(m => m.ean),
                                selectedEAN,
                            );
                        }}>
                        {selectedEAN.length > 0
                            ? t('nl-flow:searchForEanStep.confirmation')
                            : t('nl-flow:searchForEanStep.confirmationEmpty')}
                    </Button>
                </>
            ) : (
                <>
                    {
                        <>
                            <Heading error>{t('nl-flow:searchForEanStep.notFoundHeading')}</Heading>
                            <Muted>{t('nl-flow:searchForEanStep.notFoundDescription')}</Muted>
                            <Flex gap={Gap.MD}>
                                <Button outline onClick={onChangeAddress}>
                                    {t('common:changeAddress')}
                                </Button>
                            </Flex>
                        </>
                    }
                </>
            )}
        </Flex>
    );
}

export { SearchForEAN };
