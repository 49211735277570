import Card from '../../../../molecules/Card/Card.tsx';
import Heading from '../../../../atoms/Heading/Heading.tsx';
import { type ReactElement, useContext } from 'react';
import Muted from '../../../../atoms/Muted/Muted.tsx';
import Flex, { Align, FlexDirection, Gap } from '../../../../atoms/Flex/Flex.tsx';
import css from './ConsentMethodScreen.module.scss';
import API from '../../../../../api/API.ts';
import AppContext from '../../../../../store/AppContext.ts';
import { observer } from 'mobx-react-lite';
import { ConsentMethod, HostedConsentModel } from '../../../../../types/hosted-consent.types.ts';
import { AccountModel } from '../../../../../types/account.types.ts';
import { useTranslation } from 'react-i18next';

interface ConsentMethodScreenProps {
    onNext: (method: ConsentMethod | null) => void;
    partner: string;
    blanketConsent: boolean;
    consent: HostedConsentModel;
    account?: AccountModel;
}

function ConsentMethodScreen({
    onNext,
    partner,
    blanketConsent,
    consent,
    account,
}: ConsentMethodScreenProps): ReactElement {
    const { t } = useTranslation('uk-flow');
    const { ConsentStore } = useContext(AppContext);
    function handleScreenChange(method: ConsentMethod): void {
        API.axios
            .put<{ success: boolean; data: HostedConsentModel }>(
                `${import.meta.env.VITE_API}/hosted-consents/hcf/${consent.id}`,
                {
                    consent_method: method,
                },
            )
            .then(res => {
                ConsentStore.setConsent(res.data.data);
            })
            .catch(() => {});
        onNext(method);
    }

    function heading(): ReactElement {
        if (blanketConsent) {
            return (
                <Flex flexDirection={FlexDirection.COLUMN} gap={Gap.MD} align={Align.STRETCH}>
                    <Heading>{t('commercialBlanketFlow.heading', { partner })}</Heading>
                    <Muted>{t('commercialBlanketFlow.subHeading')}</Muted>
                </Flex>
            );
        }
        return (
            <>
                <Heading>{t('commercialBlanketFlow.notBlanketSubHeading')}</Heading>
            </>
        );
    }

    return (
        <Card className={css.consentMethodScreen} account={account}>
            <Flex flexDirection={FlexDirection.COLUMN} gap={Gap.XXL} align={Align.STRETCH}>
                {heading()}
                <Flex
                    className={css.methods}
                    flexDirection={FlexDirection.COLUMN}
                    gap={Gap.SM}
                    align={Align.STRETCH}>
                    <div
                        className={css.method}
                        onClick={() => {
                            handleScreenChange(ConsentMethod.LETTER_OF_AUTHORITY);
                        }}>
                        {t('commercialBlanketFlow.methods.loa.submitButton')}
                    </div>
                    <div
                        className={css.method}
                        onClick={() => {
                            handleScreenChange(ConsentMethod.DIGITAL_CONSENT);
                        }}>
                        {t('commercialBlanketFlow.methods.digital.submitButton')}
                    </div>
                    <div
                        className={css.method}
                        onClick={() => {
                            handleScreenChange(ConsentMethod.THIRD_PARTY_DIGITAL_CONSENT);
                        }}>
                        {t('commercialBlanketFlow.methods.thirdParty.submitButton')}
                    </div>
                </Flex>
            </Flex>
        </Card>
    );
}

export default observer(ConsentMethodScreen);
