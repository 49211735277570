import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ExtraNotice } from '../ExtraNotice/ExtraNotice';

interface ComponentProps {
    linkHref: string;
}

export function NeedHelpText({ linkHref }: ComponentProps): ReactElement {
    const { t } = useTranslation('common');
    return <ExtraNotice text={t('needHelpText')} linkHref={linkHref} />;
}
