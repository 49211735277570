import axios, { type AxiosResponse } from 'axios';
import { HostedConsentModel } from '../types/hosted-consent.types.ts';

export interface APIResponse<TData = any> {
    success: boolean;
    data?: TData;
    errors?: string[];
}

class API {
    axios;
    constructor() {
        this.axios = axios;
        this.axios.interceptors.response.use(
            function (response): AxiosResponse {
                return response;
            },
            async function (error) {
                return await Promise.reject(error);
            },
        );
    }

    /**
     * Sets a token as a header that will be re-used for every request.
     * @param token
     */
    setAuthHeader(token: string): void {
        this.axios.interceptors.request.use(function (config) {
            config.headers.set('x-consent-token', token);
            return config;
        });
    }

    async updateConsent(consentId: string, data: Record<string, any>): Promise<AxiosResponse> {
        return await this.axios.put<{ success: boolean; data: HostedConsentModel }>(
            `${import.meta.env.VITE_API}/hosted-consents/hcf/${consentId}`,
            data,
        );
    }

    async updateAuthorizationData(
        consentId: string,
        data: { authorization_data: Record<string, any> },
    ): Promise<AxiosResponse> {
        return await this.axios.put<{ success: boolean; data: HostedConsentModel }>(
            `${import.meta.env.VITE_API}/hosted-consents/hcf/${consentId}/authorization-data`,
            data,
        );
    }

    async removeEvidences(consentId: string): Promise<void> {
        await this.axios.put(
            `${import.meta.env.VITE_API}/hosted-consents/hcf/${consentId}/evidences`,
            {
                evidence_document: null,
                evidence_document_metadata: null,
            },
        );
    }

    async ocrVerify(
        consentId: string,
    ): Promise<AxiosResponse<APIResponse<{ is_pending_review: boolean; reason: string }>, any>> {
        return await this.axios.get<APIResponse<{ is_pending_review: boolean; reason: string }>>(
            `${import.meta.env.VITE_API}/hosted-consents/hcf/${consentId}/evidences/validations`,
        );
    }

    async approveConsent(consentId: string): Promise<AxiosResponse> {
        return await this.axios.post<{ success: boolean; data: HostedConsentModel }>(
            `${import.meta.env.VITE_API}/hosted-consents/hcf/${consentId}/approve`,
            {
                hosted_consent_version: APP_VERSION,
            },
        );
    }
}

const singleton = new API();

export default singleton;
