import { CpuChipIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline';
import { observer } from 'mobx-react-lite';
import { useContext, useState, type ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import API from '../../../../../../api/API.ts';
import AppContext from '../../../../../../store/AppContext.ts';
import {
    EnedisMeteringPoint,
    FRContextualData,
} from '../../../../../../types/country-specific/france.types.ts';
import {
    ConsentDataUsageFrance,
    FranceConsentSpecificConfiguration,
    HostedConsentModel,
} from '../../../../../../types/hosted-consent.types.ts';
import { ScreenTransition } from '../../../../../animations/ScreenTransition/ScreenTransition.tsx';
import Button from '../../../../../atoms/Button/Button.tsx';
import CollapsibleNotice from '../../../../../atoms/CollapsibleNotice/CollapsibleNotice.tsx';
import Flex, { Align, FlexDirection, Gap, Justify } from '../../../../../atoms/Flex/Flex.tsx';
import Heading from '../../../../../atoms/Heading/Heading.tsx';
import { MeterNumberBox } from '../../../../../atoms/MeterNumberBox/MeterNumberBox.tsx';
import Muted from '../../../../../atoms/Muted/Muted.tsx';
import Card from '../../../../../molecules/Card/Card.tsx';
import ConsentSteps from '../../../../../molecules/ConsentSteps/ConsentSteps.tsx';
import PlatformTermsCheckbox from '../../../../../organisms/PlatformTermsCheckbox/PlatformTermsCheckbox.tsx';
import { FlowSteps } from '../../../fr.residential.specific.tsx';
import css from './ProvideConsent.module.scss';

interface ComponentProps {
    consent: HostedConsentModel<FRContextualData, FranceConsentSpecificConfiguration>;
    onNext: () => void;
}

function ProvideConsent({ consent, onNext }: ComponentProps): ReactElement {
    // #region State
    const { t } = useTranslation('fr-flow');
    const [isLoading, setIsLoading] = useState(false);
    // Will be one key from ConsentDataUsageFrance enum
    // This should always be there in France but in respect of defensive programming
    // I'm including a fallback to ENERGY_MANAGEMENT
    const dataUsageText = t(
        `fr-flow:provideConsentStep.dataUsage.residential.${
            // This line shouldn't need the optional chaining
            consent?.country_specific_configuration?.data_usage.toLowerCase() ??
            ConsentDataUsageFrance.ENERGY_MANAGEMENT
        }`,
    );
    const { ConsentStore } = useContext(AppContext);
    const foundMeters = consent.contextual_data?.found_meter_numbers;
    const [selectedPRM, setSelectedPRM] = useState<string[]>(consent.meter_numbers ?? []);
    const hasAutomaticallyFoundMeters =
        consent?.contextual_data?.found_meter_numbers &&
        Array.isArray(foundMeters) &&
        foundMeters.length > 0;
    const isManualInput = consent?.contextual_data?.manual_input;

    // #endregion

    async function handleApproveConsent(): Promise<void> {
        setIsLoading(true);
        try {
            await API.updateConsent(consent.id, {
                meter_numbers: selectedPRM,
            });
            await API.approveConsent(consent.id);
            onNext();
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    }

    function renderStep(): ReactElement {
        return (
            <Flex flexDirection={FlexDirection.COLUMN} className={css.provideConsent}>
                <Heading>{t('fr-flow:provideConsentStep.headingResidential')}</Heading>

                <CollapsibleNotice
                    className={css.infoPanel}
                    type="display"
                    title={t('fr-flow:provideConsentStep.collectionNotice.heading')}>
                    <p>
                        <Trans i18nKey="fr-flow:provideConsentStep.collectionNotice.loadCurve" />
                    </p>
                    <p>
                        <Trans i18nKey="fr-flow:provideConsentStep.collectionNotice.technicalData" />
                    </p>
                    <p>
                        <Trans i18nKey="fr-flow:provideConsentStep.collectionNotice.prm" />
                    </p>
                </CollapsibleNotice>

                <CollapsibleNotice
                    className={css.infoPanel}
                    type="display"
                    title={t('fr-flow:provideConsentStep.dataUsage.heading')}>
                    <p>{dataUsageText}</p>
                </CollapsibleNotice>

                <div className={css.meterBox}>
                    <Muted>{t('fr-flow:provideConsentStep.consentSubtitle')}</Muted>
                    {hasAutomaticallyFoundMeters ? (
                        foundMeters.map((mp: EnedisMeteringPoint, i) => {
                            return (
                                <Flex
                                    key={mp.prm}
                                    className={
                                        selectedPRM.includes(mp.prm)
                                            ? `${css.selectedMeter} ${css.meterBlock}`
                                            : `${css.deletedMeter} ${css.meterBlock}`
                                    }
                                    justify={Justify.SPACE_BETWEEN}
                                    gap={Gap.MD}>
                                    <CpuChipIcon className={css.icon} />
                                    <span style={{ width: '80%' }}>
                                        {/* If we do not find the name from enedis, we show the name input in the first step */}
                                        <span className={css.meterBlockAddress}>
                                            {consent.contextual_data?.enedis_metering_points_data
                                                ?.meters[i].customerName ??
                                                consent.consentee_full_name?.toUpperCase()}
                                        </span>
                                        <br />
                                        <strong>PRM:</strong> {mp.prm}
                                        <br />
                                        <span className={css.meterBlockAddress}>
                                            {mp.address.join(', ')}
                                        </span>
                                    </span>
                                    {selectedPRM.includes(mp.prm) ? (
                                        <TrashIcon
                                            className={`${css.icon} ${css.clickable}`}
                                            onClick={e => {
                                                e.stopPropagation();
                                                setSelectedPRM(
                                                    selectedPRM.filter(prm => prm !== mp.prm),
                                                );
                                            }}
                                        />
                                    ) : (
                                        <PlusCircleIcon
                                            className={`${css.icon} ${css.clickable}`}
                                            onClick={e => {
                                                e.stopPropagation();
                                                setSelectedPRM([...selectedPRM, mp.prm]);
                                            }}
                                        />
                                    )}
                                </Flex>
                            );
                        })
                    ) : isManualInput ? (
                        <div className={css.meterBox}>
                            <MeterNumberBox
                                fullWidth
                                meterNumber={consent.meter_numbers?.[0] ?? ''}
                            />
                        </div>
                    ) : null}
                </div>

                <Flex gap={Gap.SM} className={css.termsCheckboxWrapper}>
                    <CollapsibleNotice
                        title={t('fr-flow:provideConsentStep.termsCheckboxHeading')}
                        iconOverride={<PlatformTermsCheckbox noContent />}>
                        <>
                            <Trans
                                i18nKey="fr-flow:provideConsentStep.termsCheckbox"
                                components={{ i18bold: <strong /> }}
                                values={{ accountName: consent.account.name }}
                            />
                        </>
                    </CollapsibleNotice>
                </Flex>

                <Button
                    fullWidth
                    onClick={handleApproveConsent}
                    disabled={!ConsentStore.termsChecked || isLoading || !selectedPRM.length}>
                    {t('common:consent')}
                </Button>
            </Flex>
        );
    }

    return (
        <Card account={consent.account}>
            <Flex gap={Gap.LG} flexDirection={FlexDirection.COLUMN} align={Align.STRETCH}>
                <ConsentSteps steps={FlowSteps} current={FlowSteps[1]} />
                <ScreenTransition screen="provide_consent">{renderStep()}</ScreenTransition>
            </Flex>
        </Card>
    );
}

export default observer(ProvideConsent);
