import { HostedConsentModel } from '../../../types/hosted-consent.types.ts';
import { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PostcodeSearch } from '../PostcodeSearch/PostcodeSearch.tsx';
import { Countries } from '../../../types/countries.types.ts';
import API from '../../../api/API.ts';
import { PostcoderSearchResult } from '../../../types/common.types.ts';
import Loading from '../../atoms/Loading/Loading.tsx';

export interface EANDataAPIMeteringPoint {
    ean: string;
    address: {
        postalCode: string;
        streetNumber: number;
        street: string;
        city: string;
    };
    product: 'ELK' | 'GAS' | 'H2';
    organisation: string;
    gridOperatorEan: string;
    specialMeteringPoint: boolean;
    gridArea: string;
    bagId: string;
}

interface EANDataAPIResponse {
    success: boolean;
    data: {
        meters: EANDataAPIMeteringPoint[];
    };
}

interface EANSearchProps {
    consent: HostedConsentModel;
    onNext: (
        meteringPoints: EANDataAPIMeteringPoint[] | null,
        address: PostcoderSearchResult,
    ) => void;
}

function EANSearch({ consent, onNext }: EANSearchProps): ReactElement {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);

    async function handleSelectAddress(address: PostcoderSearchResult): Promise<void> {
        if (address) {
            setLoading(true);
            const queryString = new URLSearchParams({
                data_source: 'edsn',
                address_identifier: `${address.number}`,
                postcode: address.postcode.split(' ').join(''),
            });
            API.axios
                .get<EANDataAPIResponse>(
                    `${import.meta.env.VITE_API}/hosted-consents/hcf/${
                        consent.id
                    }/postcode-meter-lookup?${queryString.toString()}`,
                    {
                        headers: {
                            'x-consent-token': consent.token,
                        },
                    },
                )
                .then(({ data }) => {
                    // this is duplicated because axios adds the response in a data object
                    // and we also return the response in the data object
                    const response = data.data;
                    if (response.meters.length > 0) {
                        onNext(response.meters, address);
                        setLoading(false);
                        return;
                    } else {
                        onNext([], address);
                    }
                    throw new Error(t('validation:general.noMetersFound'));
                })
                .catch(err => {
                    console.warn(t('nl-flow:errors.dataFetchError', { error: err }));
                    setLoading(false);
                    onNext(null, address);
                });
        }
    }

    if (loading) {
        return <Loading />;
    }

    return (
        <div>
            <PostcodeSearch
                onSelectAddress={handleSelectAddress}
                consent={consent}
                country={Countries.NL.countryCode}
            />
        </div>
    );
}

export default EANSearch;
