import { type ChangeEvent, type ReactElement, useState } from 'react';
import css from './ConsentExpiry.module.scss';
import Flex, { Gap, Justify } from '../../atoms/Flex/Flex.tsx';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import API from '../../../api/API.ts';
import { HostedConsentModel } from '../../../types/hosted-consent.types.ts';
import { useTranslation } from 'react-i18next';

interface ConsentExpiryProps {
    consent: HostedConsentModel;
}

function ConsentExpiry({ consent }: ConsentExpiryProps): ReactElement {
    const [isEditing, setIsEditing] = useState(false);
    const [expiry, setExpiry] = useState<Date | string>(consent.expiry);
    const { t } = useTranslation();

    async function updateConsentExpiry(date: string): Promise<void> {
        const expiry = new Date(date);
        await API.axios
            .put<HostedConsentModel>(
                `${import.meta.env.VITE_API}/hosted-consents/hcf/${consent.id}`,
                {
                    expiry,
                },
            )
            .catch();
    }

    async function handleChangeExpiry(e: ChangeEvent<HTMLInputElement>): Promise<void> {
        setExpiry(e.target.value);
        await updateConsentExpiry(e.target.value).then(() => {
            setIsEditing(false);
        });
    }

    return (
        <Flex className={css.consentExpiry} justify={Justify.SPACE_BETWEEN}>
            <div>{t('common:consentExpiryDate')}:</div>
            <Flex gap={Gap.SM}>
                {isEditing ? (
                    <input
                        type="date"
                        min={new Date().toJSON().slice(0, 10)}
                        value={new Date(expiry).toJSON().slice(0, 10)}
                        onChange={handleChangeExpiry}
                    />
                ) : (
                    <span>{new Date(expiry).toLocaleDateString()}</span>
                )}
                <PencilSquareIcon
                    className={css.edit}
                    onClick={() => {
                        setIsEditing(!isEditing);
                    }}
                />
            </Flex>
        </Flex>
    );
}

export default ConsentExpiry;
