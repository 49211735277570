import { CpuChipIcon } from '@heroicons/react/24/outline';
import { ReactElement, cloneElement } from 'react';
import Flex, { Gap, Justify } from '../Flex/Flex';
import css from './MeterNumberBox.module.scss';

export interface ComponentProps {
    meterNumber: string;
    fullWidth?: boolean;
    icon?: ReactElement;
    className?: string;
    prefix?: string | ReactElement;
}

export function MeterNumberBox(props: ComponentProps): ReactElement {
    // get the icon component and add our custom css class
    const IconComponent = props.icon ? (
        cloneElement(props.icon, { className: `${props.icon.props.className || ''} ${css.icon}` })
    ) : (
        <CpuChipIcon className={css.icon} />
    );

    const classes = [...([props.className] || []), ...(props.fullWidth ? [css.fullWidth] : [])];

    return (
        <div className={classes.join(' ')}>
            <Flex
                key={props.meterNumber}
                className={css.meterNumberBox}
                justify={Justify.SPACE_BETWEEN}
                gap={Gap.MD}>
                {IconComponent}
                {props.prefix && <span style={{ width: '10%' }}>{props.prefix}</span>}
                <span style={{ width: '80%' }}>{props.meterNumber}</span>
            </Flex>
        </div>
    );
}
