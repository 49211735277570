import { type ReactElement } from 'react';
import css from './Loading.module.scss';
import Flex from '../Flex/Flex.tsx';
import FadeIn from '../../animations/FadeIn/FadeIn.tsx';

export interface LoadingPropsType {
    className?: string;
}
function Loading({ className = '' }: LoadingPropsType): ReactElement {
    return (
        <Flex className={`${css.loading} ${className}`}>
            <FadeIn>
                <div className={css.iconContainer}>
                    <div className={css.threeDots} />
                </div>
            </FadeIn>
        </Flex>
    );
}

export default Loading;
