import React, { useState } from 'react';
import { motion, useMotionValue, useMotionValueEvent } from 'framer-motion';
import css from './DocumentScanner.module.scss';
import { useTranslation } from 'react-i18next';
import Muted from '../../atoms/Muted/Muted';
import Flex, { FlexDirection, Gap } from '../../atoms/Flex/Flex';

const animationSpeed = 1;

function DocumentScanner(): React.ReactElement {
    const left = useMotionValue('0%');
    const [headingRight, setHeadingRight] = useState(false);
    const { t } = useTranslation();

    /**
     * This basically lets you listen for whenever a value changes in motion. This is
     * used to flip the animation so that the gradient swaps sides when it reaches
     * the edge of the animation.
     */
    useMotionValueEvent(left, 'change', latest => {
        const latestAsNum = parseFloat(latest);
        if (latestAsNum > 95) {
            setHeadingRight(false);
        }
        if (latestAsNum < 5) {
            setHeadingRight(true);
        }
    });
    return (
        <Flex flexDirection={FlexDirection.COLUMN} gap={Gap.XL}>
            <div className={css.container}>
                <div className={css.leftBracket}>
                    <div className={css.top}></div>
                    <div></div>
                    <div className={css.bottom}></div>
                </div>
                <motion.div
                    style={{ left }}
                    className={`${css.scanner} ${headingRight ? css.flipped : ''}`}
                    animate={{
                        left: ['4%', '96%'], // Not quite 0 and 100% because we want to leave a gap at the edges
                    }}
                    transition={{
                        duration: animationSpeed,
                        repeat: Infinity,
                        ease: 'circInOut',
                        repeatType: 'reverse',
                    }}>
                    <div className={css.bar}>&nbsp;</div>
                    <motion.div
                        className={css.gradient}
                        animate={{
                            width: ['0px', '80px', '0px'],
                            opacity: [0, 1, 0],
                        }}
                        transition={{
                            duration: animationSpeed,
                            repeat: Infinity,
                            ease: 'easeInOut',
                            repeatType: 'reverse',
                        }}>
                        &nbsp;
                    </motion.div>
                </motion.div>
                <div className={css.rightBracket}>
                    <div className={css.top}></div>
                    <div></div>
                    <div className={css.bottom}></div>
                </div>
                <motion.div
                    animate={{
                        opacity: [0.3, 1],
                    }}
                    transition={{
                        duration: animationSpeed * 1.5, // Offset animation speed so it looks less uniform
                        repeat: Infinity,
                        ease: 'linear',
                        repeatType: 'reverse',
                    }}
                    className={css.scanning}>
                    <div className={css.dot}>&nbsp;</div>
                    <div className={css.text}>{t('common:scanningDocument')}</div>
                </motion.div>

                <motion.div
                    animate={{
                        translateY: ['5px', '-5px'],
                        /**
                         * You are more than welcome to play around with this, I wasn't sure how I felt about the rotating.
                         */
                        // rotate: ['-7deg', '3deg', '10deg'],
                    }}
                    transition={{
                        duration: animationSpeed * 1.2, // Offset animation speed so it looks less uniform
                        repeat: Infinity,
                        ease: 'easeInOut',
                        repeatType: 'reverse',
                    }}>
                    <img
                        src="/electricity-bill.svg"
                        alt="Energy Bill Icon"
                        width="100"
                        height="100"
                    />
                </motion.div>
            </div>
            <Muted>{t('common:scanningDocumentSubtitle')}</Muted>
        </Flex>
    );
}

export default DocumentScanner;
