import { type PropsWithChildren, type ReactElement } from 'react';
import css from './Heading.module.scss';

interface HeadingProps {
    error?: boolean;
}
function Heading({ children, error = false }: PropsWithChildren<HeadingProps>): ReactElement {
    return <h2 className={`${css.heading} ${error ? css.withError : ''}`}> {children}</h2>;
}

export default Heading;
