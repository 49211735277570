import type { ChangeEvent, DetailedHTMLProps, InputHTMLAttributes, ReactElement } from 'react';
import { ZodSchema } from 'zod';
import css from './TextInput.module.scss';

interface TextInputProps
    extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    className?: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
    disabled?: boolean;
    type?: string;
    name?: string;
    label?: string;
    autocomplete?: 'on' | 'off';
    validationSchema?: ZodSchema;
    onValidationError?: (error: string | null) => void;
}

function TextInput({
    className = '',
    value,
    onChange,
    placeholder = '',
    disabled = false,
    type = 'text',
    name,
    autocomplete = 'on',
    ref,
    label,
    validationSchema,
    onValidationError,
    ...nativeInputAttributes
}: TextInputProps): ReactElement {
    function handleChange(e: ChangeEvent<HTMLInputElement>): void {
        if (!disabled) {
            let error = null;
            if (validationSchema && e.target.value.length > 0) {
                const parsedData = validationSchema.safeParse(e.target.value);
                error = parsedData.error
                    ? parsedData.error.issues.find(issue => issue.code === 'invalid_string')
                          ?.message ?? parsedData.error.issues[0].message
                    : null;
            }

            onValidationError && onValidationError(error);
            onChange(e);
        }
    }

    return (
        <div className={css.textInputWrapper}>
            {label && <label className={css.label}>{label}</label>}
            <input
                value={value}
                className={`${css.textInput} ${className} ${disabled ? css.disabled : ''}`}
                type={type}
                name={name}
                ref={ref ?? undefined}
                placeholder={placeholder}
                onChange={handleChange}
                autoComplete={autocomplete}
                {...nativeInputAttributes}
            />
        </div>
    );
}

export default TextInput;
