import { ReactElement } from 'react';
import Flex, { FlexDirection, Gap } from '../../atoms/Flex/Flex.tsx';
import css from '../NotFound/NotFound.module.scss';
import FadeIn from '../../animations/FadeIn/FadeIn.tsx';

function Expired(): ReactElement {
    return (
        <Flex className={css.notFound} flexDirection={FlexDirection.COLUMN}>
            <FadeIn>
                <Flex flexDirection={FlexDirection.COLUMN} gap={Gap.XXL}>
                    <div className={css.message}>
                        <h2>Consent expired</h2>
                        <p>
                            This consent link has expired and you will be unable to make further
                            changes. To request a new consent link please contact your software
                            provider.
                        </p>
                    </div>
                    <Flex flexDirection={FlexDirection.COLUMN} gap={Gap.SM}>
                        <img className={css.logo} src="/logo-full.svg" />
                        <span>v{APP_VERSION}</span>
                    </Flex>
                </Flex>
            </FadeIn>
        </Flex>
    );
}

export { Expired };
