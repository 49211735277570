import React, { type ReactElement, useContext, useState } from 'react';
import TextareaAutosize from 'react-textarea-autosize';
import Flex, { Align, FlexDirection, Gap, Justify } from '../../../../atoms/Flex/Flex.tsx';
import TextInput from '../../../../atoms/TextInput/TextInput.tsx';
import Button from '../../../../atoms/Button/Button.tsx';
import Loading from '../../../../atoms/Loading/Loading.tsx';
import css from './RequestThirdPartyConsent.module.scss';
import Notice, { NoticeType } from '../../../../atoms/Notice/Notice.tsx';
import API from '../../../../../api/API.ts';
import AppContext from '../../../../../store/AppContext.ts';
import PrivacyText from '../../../../atoms/PrivacyText/PrivacyText.tsx';
import { HostedConsentModel } from '../../../../../types/hosted-consent.types.ts';
import { useTranslation } from 'react-i18next';

interface RequestThirdPartyConsentProps {
    onCancel: () => void;
    onComplete: () => void;
    consent: HostedConsentModel;
}

function RequestThirdPartyConsent({
    onCancel,
    onComplete,
    consent,
}: RequestThirdPartyConsentProps): ReactElement {
    const { t } = useTranslation('uk-flow');
    const [email, setEmail] = useState('');
    const [note, setNote] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const { ConsentStore } = useContext(AppContext);

    function handleChangeEmail(e: React.ChangeEvent<HTMLInputElement>): void {
        setEmail(e.target.value);
    }

    function handleChangeNote(e: React.ChangeEvent<HTMLTextAreaElement>): void {
        setNote(e.target.value);
    }

    async function handleRequestConsent(): Promise<void> {
        setError(null);
        setLoading(true);
        await API.axios
            .put<{ success: boolean; data: HostedConsentModel }>(
                `${import.meta.env.VITE_API}/hosted-consents/hcf/${consent.id}`,
                {
                    consentee_email: email,
                    consentee_email_note: note,
                },
            )
            .then(res => {
                ConsentStore.setConsent(res.data.data);
            })
            .catch(error => {
                setError(error.response.data.errors[0].message);
                setLoading(false);
            });
        await API.axios
            .post<{ success: boolean; data: HostedConsentModel }>(
                `${import.meta.env.VITE_API}/hosted-consents/hcf/${
                    consent.id
                }/send-third-party-consent-invite`,
            )
            .then(() => {
                onComplete();
            })
            .catch(error => {
                setError(error.response.data.errors[0].message);
                setLoading(false);
            });
    }

    if (loading) {
        return <Loading className={css.loading} />;
    }

    return (
        <Flex flexDirection={FlexDirection.COLUMN} gap={Gap.LG} align={Align.STRETCH}>
            <TextInput
                label={t('common:email')}
                value={email}
                onChange={handleChangeEmail}
                type="Email"
                name="email"
            />
            <TextareaAutosize
                className={css.textarea}
                placeholder={`${t('common:note')} (${t('common:optional')})`}
                minRows={3}
                maxRows={6}
                maxLength={400}
                value={note}
                onChange={handleChangeNote}
            />
            {error != null && <Notice type={NoticeType.ERROR}>{error}</Notice>}
            <Flex justify={Justify.SPACE_BETWEEN} gap={Gap.LG}>
                <Button onClick={onCancel} outline>
                    {t('common:back')}
                </Button>
                <Button onClick={handleRequestConsent} disabled={email.length === 0}>
                    {t('common:next')}
                </Button>
            </Flex>
            <PrivacyText />
        </Flex>
    );
}

export default RequestThirdPartyConsent;
