import { makeAutoObservable } from 'mobx';
import { Languages } from '../components/atoms/LanguageSelection/LanguageSelection.tsx';

class LanguageStore {
    availableLanguages: Languages[] = [];
    defaultLanguage: Languages = Languages.EN;
    currentlySelectedLanguage: Languages | null = null;
    constructor() {
        makeAutoObservable(this);
    }

    setAvailableLanguages(languages: typeof this.availableLanguages): void {
        this.availableLanguages = languages;
    }

    setDefaultLanguage(language: typeof this.defaultLanguage): void {
        this.defaultLanguage = language;
    }

    setCurrentlySelectedLanguage(language: Languages): void {
        this.currentlySelectedLanguage = language;
    }
}

const singleton = new LanguageStore();

export default singleton;
