import { AxiosError } from 'axios';
import { t } from 'i18next';
import { ReactElement, useRef, useState } from 'react';
import { PostcoderAutocompleteResult, PostcoderSearchResult } from '../../../types/common.types';
import { CountriesEnumType } from '../../../types/countries.types';
import { HostedConsentModel } from '../../../types/hosted-consent.types';
import { useClickOutsideRef } from '../../../utils/useClickOutsideRef';
import Muted from '../../atoms/Muted/Muted';
import { SearchInput, SearchInputProps } from '../../atoms/SearchInput/SearchInput';
import { ResultsBox } from '../../molecules/ResultsBox/ResultsBox';
import css from './PostcodeSearch.module.scss';
import API, { APIResponse } from '../../../api/API';

export interface ComponentProps extends Omit<SearchInputProps, 'label' | 'loading' | 'onSearch'> {
    onSelectAddress: (address: any) => Promise<void>;
    onError?: (errors: Error | AxiosError | null) => void;
    onLoading?: (loadingState: boolean) => void;
    consent: HostedConsentModel<any, any>;
    label?: string;
    country: CountriesEnumType[keyof CountriesEnumType];
}

export function PostcodeSearch(props: ComponentProps): ReactElement {
    const postcodeSearchRef = useRef(null);
    const [addressResults, setAddressResults] = useState<PostcoderAutocompleteResult[]>([]);
    const [addressResultsOpen, setAddressResultsOpen] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedAddress, setSelectedAddress] = useState<PostcoderSearchResult | null>(null);

    function handleLoading(loading: boolean): void {
        setLoading(loading);
        if (props.onLoading && typeof props.onLoading === 'function') {
            props.onLoading(loading);
        }
    }

    function handleErrors(errors: any): void {
        if (props.onError && typeof props.onError === 'function') {
            props.onError(errors);
        }
    }

    async function handleSearch(query: string): Promise<void> {
        setSearchQuery(query);
        if (query.length > 0) {
            handleErrors(null);
            handleLoading(true);
            setAddressResultsOpen(false);

            try {
                const { data } = await API.axios.get<APIResponse<PostcoderAutocompleteResult[]>>(
                    `${import.meta.env.VITE_API}/hosted-consents/hcf/${
                        props.consent.id
                    }/autocomplete-search`,
                    {
                        params: {
                            query,
                            country: props.country,
                        },
                    },
                );
                if (data.success) {
                    if (data.data) {
                        setAddressResults(data.data);
                        setAddressResultsOpen(true);
                    }
                }
            } catch (err) {
                if (err instanceof AxiosError) {
                    if (err.response) {
                        handleErrors(err.response.data.errors);
                    }
                }
                if (err instanceof Error) {
                    handleErrors(err);
                }

                setAddressResults([]);
                setAddressResultsOpen(false);
            } finally {
                handleLoading(false);
            }
        }
    }

    async function handleRetrieveAutocomplete(id: string): Promise<void> {
        try {
            setLoading(true);
            setAddressResultsOpen(false);
            const { data } = await API.axios.get<APIResponse>(
                `${import.meta.env.VITE_API}/hosted-consents/hcf/${
                    props.consent.id
                }/autocomplete-retrieve`,
                {
                    params: {
                        id,
                        query: searchQuery,
                        country: props.country,
                    },
                },
            );
            setAddressResultsOpen(false);
            setSelectedAddress(data.data);
            setLoading(false);
            await props.onSelectAddress(data.data);
        } catch (e: any) {
            handleErrors(e.response.data.errors);
        }
    }

    /**
     * If we click outside the postcode search, we should close the form.
     */
    useClickOutsideRef(postcodeSearchRef, () => {
        setAddressResultsOpen(false);
    });

    return (
        <div className={css.postcodeSearch} ref={postcodeSearchRef}>
            <SearchInput
                onSearch={handleSearch}
                label={props.label ?? t('common:searchAddress')}
                loading={loading}
                initialValue={props.initialValue}
                value={selectedAddress?.summaryline ?? undefined}
                onClick={() => {
                    addressResults.length && setAddressResultsOpen(true);
                }}
            />
            {addressResultsOpen && (
                <ResultsBox className={css.results}>
                    {addressResults.length === 0 && <Muted>{t('common:noResults')}</Muted>}
                    {addressResults.map((result, i) => {
                        return (
                            <div
                                key={i}
                                className={css.result}
                                aria-live="polite"
                                role="region"
                                aria-atomic="true"
                                onClick={async () => {
                                    await handleRetrieveAutocomplete(result.id);
                                }}>
                                {result.summaryline} {result.locationsummary}
                            </div>
                        );
                    })}
                </ResultsBox>
            )}
        </div>
    );
}
