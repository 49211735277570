import { ChangeEvent, useContext, useState, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../../../../api/API.ts';
import AppContext from '../../../../../../store/AppContext.ts';
import { HostedConsentModel } from '../../../../../../types/hosted-consent.types.ts';
import { DataFetching } from '../../../../../animations/DataFetching/DataFetching.tsx';
import { ScreenTransition } from '../../../../../animations/ScreenTransition/ScreenTransition.tsx';
import Button from '../../../../../atoms/Button/Button.tsx';
import { ErrorNotice } from '../../../../../atoms/ErrorNotice/ErrorNotice.tsx';
import Flex, { Align, FlexDirection, Gap } from '../../../../../atoms/Flex/Flex.tsx';
import Heading from '../../../../../atoms/Heading/Heading.tsx';
import Muted from '../../../../../atoms/Muted/Muted.tsx';
import TextInput from '../../../../../atoms/TextInput/TextInput.tsx';
import Card from '../../../../../molecules/Card/Card.tsx';
import ConsentSteps from '../../../../../molecules/ConsentSteps/ConsentSteps.tsx';
import { NLCommercialSpecificConsentSteps } from '../../../nl.commercial.specific.tsx';
import css from './EANCollection.module.scss';
import { SearchForEAN } from '../SearchForEAN/SearchForEAN.tsx';

export enum EANCollectionSteps {
    ADDRESS_COLLECTION = 'address_collection',
    EAN_COLLECTION = 'ean_collection',
}

interface EANCollectionProps {
    consent: HostedConsentModel;
    onNext: () => void;
}

export interface NLPostcodeSearchResult {
    postcode?: string;
    house_number?: string;
    house_number_addition?: string;
}

function EANCollection({ consent, onNext }: EANCollectionProps): ReactElement {
    // #region State
    const [errors, setErrors] = useState<null | string | string[]>(null);
    const [step, setStep] = useState(EANCollectionSteps.ADDRESS_COLLECTION);
    const { t } = useTranslation('nl-flow');

    /**
     * Stuff the user provides or clicks on.
     */
    const [selectedAddress, setSelectedAddress] = useState<NLPostcodeSearchResult | null>(null);

    const { ConsentStore } = useContext(AppContext);
    // #endregion

    // #region Handlers
    function handleInput(key: keyof NLPostcodeSearchResult) {
        return ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
            if (selectedAddress == null) {
                setSelectedAddress({ [key]: value.toUpperCase() } satisfies NLPostcodeSearchResult);
                return;
            }
            setSelectedAddress({ ...selectedAddress, [key]: value.toUpperCase() });
        };
    }
    async function handleSelectAddress(): Promise<void> {
        try {
            if (!selectedAddress?.house_number || !selectedAddress?.postcode) {
                setErrors(t('nl-flow:errors.missingAddress'));
                return;
            }
            const { data: apiResponse } = await API.updateConsent(consent.id, {
                contextual_data: {
                    ...consent.contextual_data,
                    house_number: selectedAddress?.house_number,
                    house_number_addition: selectedAddress?.house_number_addition,
                    postcode: selectedAddress?.postcode,
                },
            });
            ConsentStore.setConsent(apiResponse.data);
            setStep(EANCollectionSteps.EAN_COLLECTION);
        } catch (e: any) {
            setErrors(e);
        }
    }

    async function handleUpdateMeterNumber(
        foundMeters: string[],
        selectedMeters: string[],
    ): Promise<void> {
        try {
            const res = await API.updateConsent(consent.id, {
                meter_numbers: selectedMeters,
                contextual_data: { ...consent.contextual_data, found_meter_numbers: foundMeters },
            });
            ConsentStore.setConsent(res.data.data);
            onNext();
        } catch (e: any) {
            setErrors(e);
        }
    }

    // #endregion

    function renderStep(): ReactElement {
        switch (step) {
            case EANCollectionSteps.ADDRESS_COLLECTION:
                return (
                    <Flex
                        className={css.addressCollection}
                        gap={Gap.MD}
                        flexDirection={FlexDirection.COLUMN}
                        align={Align.STRETCH}>
                        <Heading>{t('nl-flow:eanCollectionStep.heading')}</Heading>
                        <Muted>{t('nl-flow:eanCollectionStep.description')}</Muted>
                        {errors != null && <ErrorNotice errors={errors} />}
                        <div className={css.postcodeSearch}>
                            <TextInput
                                label={t('nl-flow:eanCollectionStep.postcodeLabel')}
                                type="text"
                                className={css.postcodeField}
                                onChange={handleInput('postcode')}
                                value={selectedAddress?.postcode ?? ''}
                            />
                            <Flex gap={Gap.SM} flexDirection={FlexDirection.ROW}>
                                <TextInput
                                    label={t('nl-flow:eanCollectionStep.houseNumberLabel')}
                                    type="number"
                                    className={css.buildingNumberField}
                                    onChange={handleInput('house_number')}
                                    value={selectedAddress?.house_number ?? ''}
                                />
                                <TextInput
                                    label={t('nl-flow:eanCollectionStep.houseNumberAdditionLabel')}
                                    type="text"
                                    className={css.buldingNumberAdditionField}
                                    onChange={handleInput('house_number_addition')}
                                    value={selectedAddress?.house_number_addition ?? ''}
                                />
                            </Flex>
                            <Button
                                fullWidth={true}
                                onClick={handleSelectAddress}
                                disabled={
                                    !selectedAddress?.house_number || !selectedAddress?.postcode
                                }>
                                {t('common:confirmAddress')}
                            </Button>
                        </div>
                    </Flex>
                );
            case EANCollectionSteps.EAN_COLLECTION:
                return (
                    <SearchForEAN
                        consent={consent}
                        address={selectedAddress}
                        onChangeAddress={() => {
                            setStep(EANCollectionSteps.ADDRESS_COLLECTION);
                        }}
                        onSelectEAN={async (found: string[], selected: string[]) => {
                            await handleUpdateMeterNumber(found, selected);
                        }}
                        setStep={setStep}
                    />
                );
            default:
                return <DataFetching text={t('nl-flow:common.searchingEanCode')} />;
        }
    }

    return (
        <Card account={consent.account}>
            <Flex gap={Gap.LG} flexDirection={FlexDirection.COLUMN} align={Align.STRETCH}>
                <ConsentSteps
                    steps={NLCommercialSpecificConsentSteps}
                    current={NLCommercialSpecificConsentSteps[0]}
                />
                <ScreenTransition screen={step}>{renderStep()}</ScreenTransition>
            </Flex>
        </Card>
    );
}

export default EANCollection;
