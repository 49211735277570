import { type PropsWithChildren, type ReactElement } from 'react';
import css from './Button.module.scss';
interface ButtonProps {
    className?: string;
    onClick?: () => void;
    outline?: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
}

function Button({
    className = '',
    children,
    onClick,
    outline = false,
    disabled = false,
    fullWidth = false,
}: PropsWithChildren<ButtonProps>): ReactElement {
    function handleClick(): void {
        if (!disabled && onClick) {
            onClick();
        }
    }

    return (
        <button
            type={onClick ? 'button' : 'submit'}
            onClick={handleClick}
            className={`
                ${css.button}
                ${className}
                ${outline ? css.outline : ''}
                ${disabled ? css.disabled : ''}
                ${fullWidth ? css.fullWidth : ''}
            `}>
            {children}
        </button>
    );
}

export default Button;
