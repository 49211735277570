import { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import css from './ContentToggle.module.scss';
import Flex, { Align, Justify } from '../Flex/Flex.tsx';
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import FadeIn from '../../animations/FadeIn/FadeIn.tsx';

interface ContentToggleProps {
    title: string;
    className?: string;
    open?: boolean;
    onToggled?: (open: boolean) => void;
}

function ContentToggle({
    title,
    className,
    children,
    open,
    onToggled,
}: PropsWithChildren<ContentToggleProps>): ReactElement {
    const [isOpen, setIsOpen] = useState(open ?? false);

    function handleToggle(): void {
        setIsOpen(!isOpen);
        if (onToggled) {
            onToggled(!isOpen);
        }
    }

    useEffect(() => {
        if (open !== undefined) {
            setIsOpen(open);
        }
    }, [open]);

    return (
        <div onClick={handleToggle} className={`${css.contentToggle} ${className ?? ''}`}>
            <Flex className={css.header} justify={Justify.SPACE_BETWEEN} align={Align.CENTER}>
                <div className={css.title}>{title}</div>
                {isOpen ? (
                    <ChevronDownIcon className={css.icon} />
                ) : (
                    <ChevronRightIcon className={css.icon} />
                )}
            </Flex>
            <div className={`${css.content} ${isOpen ? css.open : css.closed}`}>
                {isOpen && <FadeIn>{children}</FadeIn>}
            </div>
        </div>
    );
}

export { ContentToggle };
