import { ChangeEvent, useContext, useState, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../../../../api/API.ts';
import AppContext from '../../../../../../store/AppContext.ts';
import { HostedConsentModel } from '../../../../../../types/hosted-consent.types.ts';
import { ScreenTransition } from '../../../../../animations/ScreenTransition/ScreenTransition.tsx';
import Button from '../../../../../atoms/Button/Button.tsx';
import { ErrorNotice } from '../../../../../atoms/ErrorNotice/ErrorNotice.tsx';
import Flex, { Align, FlexDirection, Gap } from '../../../../../atoms/Flex/Flex.tsx';
import Heading from '../../../../../atoms/Heading/Heading.tsx';
import TextInput from '../../../../../atoms/TextInput/TextInput.tsx';
import Card from '../../../../../molecules/Card/Card.tsx';
import ConsentSteps from '../../../../../molecules/ConsentSteps/ConsentSteps.tsx';
import { FlowSteps } from '../../../es.commercial.specific.tsx';
import css from './CompanyInfo.module.scss';
import { AddressBox } from '../../../../../atoms/AddressBox/AddressBox.tsx';
import { BuildingStorefrontIcon } from '@heroicons/react/24/outline';

export enum Steps {
    INFO_COLLECTION = 'info_collection',
    INFO_CONFIRM = 'info_confirm',
}

interface ComponentProps {
    consent: HostedConsentModel;
    onNext: () => void;
}

interface Info {
    company_name?: string;
    nif?: string;
}

function CompanyInfoCollection({ consent, onNext }: ComponentProps): ReactElement {
    // #region State
    const [error, setError] = useState<null | string>(null);
    const [step, setStep] = useState(Steps.INFO_COLLECTION);
    const { t } = useTranslation('es-flow');

    /**
     * Stuff the user provides or clicks on.
     */
    const [companyInfo, setCompanyInfo] = useState<Info>({});

    const { ConsentStore } = useContext(AppContext);
    // #endregion

    // #region Handlers
    function handleInput(key: keyof Info) {
        return ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
            let parsedValue = value;
            let error = null;

            if (key === 'nif') {
                parsedValue = value.toUpperCase();
                if (parsedValue.length > 9) {
                    return;
                } else if (
                    parsedValue.length > 0 &&
                    !/^[YN][0-9]{7}[A-Z]{1}$/.test(parsedValue.toUpperCase())
                ) {
                    error = t('es-flow:errors.nifFormat');
                }
            }

            setError(error);
            setCompanyInfo({ ...companyInfo, [key]: parsedValue });
        };
    }
    async function handleFillPersonInfo(): Promise<void> {
        try {
            if (!companyInfo?.nif || !companyInfo?.company_name) {
                setError(t('es-flow:errors.missingInfo'));
                return;
            }
            const { data: apiResponse } = await API.updateAuthorizationData(consent.id, {
                authorization_data: {
                    nif: companyInfo.nif,
                    company_name: companyInfo.company_name,
                },
            });
            ConsentStore.setConsent({
                ...consent,
                authorization_data: apiResponse.authorization_data,
            });
            setStep(Steps.INFO_CONFIRM);
        } catch (e: any) {
            setError(e);
        }
    }

    function handleConfirm(): void {
        onNext();
    }

    function handleBack(): void {
        setStep(Steps.INFO_COLLECTION);
    }

    // #endregion

    function renderStep(): ReactElement {
        switch (step) {
            case Steps.INFO_COLLECTION:
                return (
                    <Flex
                        className={css.info}
                        gap={Gap.MD}
                        flexDirection={FlexDirection.COLUMN}
                        align={Align.STRETCH}>
                        <Heading>{t('es-flow:commercialFlow:collectionStep.heading')}</Heading>
                        {error != null && <ErrorNotice errors={error} />}
                        <div className={css.infoBox}>
                            <TextInput
                                label={t('common:companyName')}
                                type="text"
                                className={css.fullNameField}
                                onChange={handleInput('company_name')}
                                value={companyInfo?.company_name ?? ''}
                            />
                            <TextInput
                                label={t('es-flow:commercialFlow:collectionStep.nifLabel')}
                                type="text"
                                className={css.dniField}
                                onChange={handleInput('nif')}
                                value={companyInfo?.nif ?? ''}
                            />
                            <Button
                                fullWidth={true}
                                onClick={handleFillPersonInfo}
                                disabled={!companyInfo.nif || !companyInfo.company_name || !!error}>
                                {t('common:next')}
                            </Button>
                        </div>
                    </Flex>
                );
            case Steps.INFO_CONFIRM:
                return (
                    <Flex
                        className={css.infoConfirm}
                        gap={Gap.MD}
                        flexDirection={FlexDirection.COLUMN}
                        align={Align.STRETCH}>
                        <Heading>{t('es-flow:commercialFlow:confirmStep.heading')}</Heading>
                        {error != null && <ErrorNotice errors={error} />}
                        <div className={css.infoBox}>
                            <AddressBox
                                address={`${companyInfo?.company_name}, NIF: ${companyInfo?.nif}`}
                                icon={<BuildingStorefrontIcon />}
                            />
                        </div>
                        <Flex flexDirection={FlexDirection.ROW} gap={Gap.SM}>
                            <Button outline={true} fullWidth={true} onClick={handleBack}>
                                {t('common:back')}
                            </Button>
                            <Button fullWidth={true} onClick={handleConfirm}>
                                {t('common:confirm')}
                            </Button>
                        </Flex>
                    </Flex>
                );
            default:
                return <></>;
        }
    }

    return (
        <Card account={consent.account}>
            <Flex gap={Gap.LG} flexDirection={FlexDirection.COLUMN} align={Align.STRETCH}>
                <ConsentSteps steps={FlowSteps} current={FlowSteps[0]} />
                <ScreenTransition screen={step}>{renderStep()}</ScreenTransition>
            </Flex>
        </Card>
    );
}

export default CompanyInfoCollection;
