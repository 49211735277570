import MagnifyingGlassIcon from '@heroicons/react/24/outline/MagnifyingGlassIcon';
import { useKeyDown } from 'a11y-onkeydown';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';
import { Spinner } from '../Spinner/Spinner.tsx';
import css from './SearchInput.module.scss';
import useDebouncedEffect from '../../../utils/useDebouncedEffect.ts';

export interface SearchInputProps {
    disableDebounce?: boolean;
    delay?: number;
    initialValue?: string;
    label: string;
    onSearch: (value: string) => void;
    placeholder?: string;
    loading?: boolean;
    onClick?: () => void;
    value?: string;
}

function SearchInput({
    initialValue = '',
    onSearch,
    label,
    placeholder,
    loading = false,
    value,
    onClick,
    disableDebounce = false,
    delay = 500,
}: SearchInputProps): ReactElement {
    const [search, setSearch] = useState<string>(initialValue);

    useEffect(() => {
        if (value) {
            setSearch(value);
        }
    }, [value]);

    useDebouncedEffect(
        () => {
            if (search != null && !disableDebounce && !loading) {
                onSearch(search);
            }
        },
        [search],
        delay,
    );

    function handleChange(e: ChangeEvent<HTMLInputElement>): void {
        setSearch(e.target.value);
    }

    function handleSearch(): void {
        if (search.length > 0 && !loading) {
            onSearch(search);
        }
    }

    function handleClick(): void {
        if (onClick) {
            onClick();
        }
    }

    return (
        <>
            <label htmlFor="addressInput" className={css.label}>
                {label}
            </label>
            <form
                onClick={handleClick}
                className={css.searchInputWrapper}
                onSubmit={e => {
                    e.preventDefault();
                    handleSearch();
                }}>
                <input
                    className={css.searchInput}
                    type="search"
                    role="searchbox"
                    name="addressInput"
                    onChange={handleChange}
                    value={search}
                    autoComplete="off"
                    placeholder={placeholder ?? ''}
                    aria-disabled={loading}
                    aria-label={label}
                    aria-description="search results will appear below"
                />
                {loading ? (
                    <Spinner
                        className={`${css.icon} ${css.spinner}`}
                        aria-controls="input"
                        aria-label="Loading"
                        aria-description="Loading"
                        aria-disabled={true}
                        aria-hidden={false}
                    />
                ) : (
                    <MagnifyingGlassIcon
                        role="button"
                        tabIndex={0}
                        className={css.icon}
                        onClick={handleSearch}
                        onKeyDown={useKeyDown(handleSearch)}
                        focusable={true}
                        aria-controls="addressInput"
                        aria-label="Search"
                        aria-description="trigger search for address"
                        aria-disabled={loading}
                        aria-hidden={loading}
                    />
                )}
            </form>
        </>
    );
}

export { SearchInput };
