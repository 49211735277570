import { ReactElement } from 'react';
import Flex, { Align, Gap } from '../Flex/Flex.tsx';
import css from './AddressBox.module.scss';
import { HomeIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

interface AddressBoxProps {
    address: string;
    onChange?: () => void;
    icon?: ReactElement;
}

function AddressBox({ address, onChange, icon }: AddressBoxProps): ReactElement {
    const { t } = useTranslation();
    return (
        <Flex className={css.addressBox} gap={Gap.MD} align={Align.START}>
            {icon ?? <HomeIcon className={css.homeIcon} />}
            <div className={css.address}>
                {address.split(', ').map((line, i) => {
                    return <div key={i}>{line}</div>;
                })}
            </div>
            {onChange && (
                <span className={css.change} onClick={onChange}>
                    {t('common:change')}
                </span>
            )}
        </Flex>
    );
}

export { AddressBox };
