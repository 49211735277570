import { InformationCircleIcon } from '@heroicons/react/24/solid';
import Flex, { FlexDirection, Gap, Align, Justify } from '../Flex/Flex';
import Notice, { NoticeType } from '../Notice/Notice';
import css from './ExtraNotice.module.scss';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'react-tooltip';

interface ComponentProps {
    linkHref?: string;
    text: string;
    tooltip?: boolean;
    tooltipText?: string | ReactElement;
    tooltipPosition?: 'top' | 'bottom' | 'left' | 'right';
}

export function ExtraNotice({
    linkHref,
    text,
    tooltip,
    tooltipText,
    tooltipPosition,
}: ComponentProps): ReactElement {
    const { t } = useTranslation('common');
    return (
        <Notice type={NoticeType.INFO} className={css.infoBox}>
            <Flex
                flexDirection={FlexDirection.ROW}
                gap={Gap.SM}
                align={Align.CENTER}
                justify={Justify.START}
                fullWidth={true}
                className={css.infoFlex}>
                <InformationCircleIcon className={css.icon} />
                <span>
                    {text}{' '}
                    {linkHref && !tooltip && (
                        <a className={css.readMore} href={linkHref} target="_blank">
                            {t('readMore')}
                        </a>
                    )}
                    {tooltip && !linkHref && tooltipText && (
                        <>
                            <Tooltip
                                className={css.tooltipBubble}
                                anchorSelect="#tooltipAnchor"
                                place={tooltipPosition ?? 'top'}
                                children={tooltipText}
                            />
                            <span id="tooltipAnchor" className={css.tooltipText}>
                                {t('readMore')}
                            </span>
                        </>
                    )}
                </span>
            </Flex>
        </Notice>
    );
}
