import { ReactElement, useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import API from '../../../../../../api/API.ts';
import AppContext from '../../../../../../store/AppContext.ts';
import { PostcoderSearchResult } from '../../../../../../types/common.types.ts';
import type {
    EnedisManualMeterLookupResponse,
    EnedisMeteringPoint,
    EnedisMeteringPointsApiResponse,
    FRContextualData,
} from '../../../../../../types/country-specific/france.types.ts';
import {
    FranceConsentSpecificConfiguration,
    HostedConsentModel,
} from '../../../../../../types/hosted-consent.types.ts';
import { DataFetching } from '../../../../../animations/DataFetching/DataFetching.tsx';
import { AddressBox } from '../../../../../atoms/AddressBox/AddressBox.tsx';
import Button from '../../../../../atoms/Button/Button.tsx';
import CollapsibleNotice from '../../../../../atoms/CollapsibleNotice/CollapsibleNotice.tsx';
import Flex, { Align, FlexDirection, Gap } from '../../../../../atoms/Flex/Flex.tsx';
import Heading from '../../../../../atoms/Heading/Heading.tsx';
import Muted from '../../../../../atoms/Muted/Muted.tsx';
import TextInput from '../../../../../atoms/TextInput/TextInput.tsx';
import { FRPersonalData } from '../PRMCollection/PRMCollection.tsx';
import css from './SearchForPRM.module.scss';

interface ComponentProps {
    personalData: Required<FRPersonalData>;
    address: PostcoderSearchResult | null;
    onChangeAddress: () => void;
    onSelect: (
        found: EnedisMeteringPoint[],
        selected: string[],
        isManual: boolean,
    ) => Promise<void>;
}

function SearchForPRM({
    address,
    personalData,
    onChangeAddress,
    onSelect,
}: ComponentProps): ReactElement {
    const { t } = useTranslation('fr-flow');
    const [loading, setLoading] = useState(true);
    const [meterFound, setMeterFound] = useState<boolean | null>(null);
    const [selectedMeter, setSelectedMeter] = useState<string[]>([]);
    const [isDeadEnd, setIsDeadEnd] = useState(false);
    const { ConsentStore } = useContext(AppContext);
    const consent: HostedConsentModel<FRContextualData, FranceConsentSpecificConfiguration> | null =
        ConsentStore.consent;

    // sanity check, this should never happen
    if (!consent) return <></>;

    const handleReversePRMLookup = async (prm: string): Promise<void> => {
        setLoading(true);

        const queryString = new URLSearchParams({
            data_source: 'enedis',
            meter_number: prm,
        });

        try {
            const { data } = await API.axios.get<EnedisManualMeterLookupResponse>(
                `${import.meta.env.VITE_API}/hosted-consents/hcf/${
                    consent.id
                }/manual-meter-lookup?${queryString.toString()}`,
                {
                    headers: {
                        'x-consent-token': consent.token,
                    },
                },
            );

            // if there's any errors we reset the state to avoid issues
            if (!data.success) {
                setMeterFound(false);
                setSelectedMeter([]);
                // this will trigger the dead end state
                setIsDeadEnd(true);
                return;
            }

            // on found meters we set the state to the only meter found
            const response = data.data;
            setMeterFound(true);
            setSelectedMeter([prm]);
            // just in case
            setIsDeadEnd(false);
            consent.contextual_data = {
                ...consent.contextual_data,
                enedis_manual_lookup_data: response,
            };
            await onSelect(
                [
                    {
                        prm,
                        // This needs to be an array because in ProvideConsent we will join them all together
                        address: [
                            response.meter.address.numberAndStreet,
                            response.meter.address.postalCode,
                            response.meter.address.municipality.label,
                        ],
                    },
                ],
                [prm],
                true,
            );
        } catch (err) {
            setMeterFound(false);
            setSelectedMeter([]);
            setIsDeadEnd(true);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (address) {
            setLoading(true);
            if (!address.number || !address.postcode) {
                return;
            }
            setLoading(true);

            const queryString = new URLSearchParams({
                data_source: 'enedis',
                first_name: personalData.first_name,
                last_name: personalData.last_name,
                street: address.street,
                municipality: address.posttown,
                number: address.number,
                postcode: address.postcode,
            });

            API.axios
                .get<EnedisMeteringPointsApiResponse>(
                    `${import.meta.env.VITE_API}/hosted-consents/hcf/${
                        consent?.id
                    }/postcode-meter-lookup?${queryString.toString()}`,
                    {
                        headers: {
                            'x-consent-token': consent?.token,
                        },
                    },
                )
                .then(async ({ data }) => {
                    // this is duplicated because axios adds the response in a data object
                    // and we also return the response in the data object
                    const response = data.data;
                    if (response.meters.length > 0) {
                        setLoading(false); // setting it here so the data fetching title changes
                        setMeterFound(true);
                        setSelectedMeter([response.meters[0].prm]);
                        const allPRMs = response.meters.map(m => m.prm);
                        // Save all returned data from enedis
                        consent.contextual_data = {
                            ...consent.contextual_data,
                            enedis_metering_points_data: response,
                        };
                        // Don't select the first meter automatically
                        // There's a selection in the ProvideConsent component
                        await onSelect(response.meters, allPRMs, false);
                        return;
                    }
                    setMeterFound(false);
                })
                .catch(err => {
                    console.warn(t('fr-flow:errors.dataFetchError', { error: err }));
                    setMeterFound(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, []);

    if (loading || meterFound === null) {
        return <DataFetching text={t('fr-flow:common.searchingPrmCode')} />;
    }
    if (!address) {
        throw new Error('Finished loading but no address selected in SearchForPRM.tsx');
    }

    if (isDeadEnd) {
        return (
            <Flex flexDirection={FlexDirection.COLUMN} gap={Gap.MD}>
                <Heading error>{t('fr-flow:searchForPrmStep.deadEndHeading')}</Heading>
                <Muted>{t('fr-flow:searchForPrmStep.deadEndDescription')}</Muted>
                <Button fullWidth={true} outline onClick={onChangeAddress}>
                    {t('fr-flow:searchForPrmStep.tryWithDifferentAddress')}
                </Button>
            </Flex>
        );
    }

    return meterFound !== null && meterFound ? (
        <DataFetching text="Updating meter data..." />
    ) : (
        <Flex
            flexDirection={FlexDirection.COLUMN}
            align={Align.STRETCH}
            gap={Gap.LG}
            className={css.searchForMeter}>
            <Heading error>{t('fr-flow:searchForPrmStep.notFoundHeading')}</Heading>
            <AddressBox
                address={`${personalData.first_name} ${personalData.last_name}, ${address.summaryline}`}
            />
            <Muted>{t('fr-flow:searchForPrmStep.notFoundDescription')}</Muted>
            <Flex gap={Gap.MD} flexDirection={FlexDirection.COLUMN}>
                <TextInput
                    placeholder={t('fr-flow:searchForPrmStep:manualMeterInput')}
                    value={selectedMeter[0]}
                    onChange={e => {
                        setSelectedMeter([e.target.value]);
                    }}
                    autocomplete="off"
                />

                <Button
                    fullWidth={true}
                    disabled={!selectedMeter[0]}
                    onClick={async () => {
                        await handleReversePRMLookup(selectedMeter[0]);
                    }}>
                    {t('fr-flow:searchForPrmStep.continueWithManualInput')}
                </Button>
                <Flex gap={Gap.SM} className={css.buttonSeparatorWrapper}>
                    <span className={css.buttonSeparator}></span>
                    <span className={css.buttonSeparatorText}>{t('common:or')}</span>
                    <span className={css.buttonSeparator}></span>
                </Flex>
                <Button fullWidth={true} outline onClick={onChangeAddress}>
                    {t('fr-flow:searchForPrmStep.tryWithDifferentAddress')}
                </Button>
            </Flex>
            <CollapsibleNotice
                className={css.notice}
                type={'info'}
                title={t('fr-flow:searchForPrmStep.notFoundHelpNotice.title')}>
                <ul>
                    <li>
                        <Trans i18nKey={'fr-flow:searchForPrmStep.notFoundHelpNotice.linky'} />
                    </li>
                    <li>
                        <Trans i18nKey={'fr-flow:searchForPrmStep.notFoundHelpNotice.electronic'} />
                    </li>
                    <li>
                        <Trans
                            i18nKey={
                                'fr-flow:searchForPrmStep.notFoundHelpNotice.electromechanical'
                            }
                        />
                    </li>
                    <li>
                        <Trans i18nKey={'fr-flow:searchForPrmStep.notFoundHelpNotice.bill'} />
                    </li>
                </ul>
            </CollapsibleNotice>
        </Flex>
    );
}

export { SearchForPRM };
