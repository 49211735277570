import { HostedConsentModel } from '../types/hosted-consent.types.ts';

function navigateToCallback(consent: HostedConsentModel<any, any>, success?: boolean): void {
    const url = new URL(consent.redirect_url);
    const params = new URLSearchParams(url.search);
    if (success === true) {
        params.set('success', 'true');
    } else if (success === false) {
        params.set('success', 'false');
    }

    params.set('consent_id', consent.id);
    window.location.href = `${consent.redirect_url}?${params.toString()}`;
}

export default navigateToCallback;
