export const Countries = {
    US: {
        flagIcon: '🇺🇸',
        dialingCode: '1',
        countryCode: 'US',
        phoneRegex: /^\d{3}\d{3}\d{4}$/,
        phoneMask: '### ### ####',
        name: 'United States',
        localizedName: 'United States',
        language: 'English',
        languageCode: 'en',
    },
    CA: {
        flagIcon: '🇨🇦',
        dialingCode: '1',
        countryCode: 'CA',
        phoneRegex: /^\d{3}\d{3}\d{4}$/,
        phoneMask: '### ### ####',
        name: 'Canada',
        localizedName: 'Canada',
        language: 'English/French',
        languageCode: 'en/fr',
    },
    RU: {
        flagIcon: '🇷🇺',
        dialingCode: '7',
        countryCode: 'RU',
        phoneRegex: /^\d{3}\d{7}$/,
        phoneMask: '### #######',
        name: 'Russia',
        localizedName: 'Россия',
        language: 'Русский',
        languageCode: 'ru',
    },
    GR: {
        flagIcon: '🇬🇷',
        dialingCode: '30',
        countryCode: 'GR',
        phoneRegex: /^\d{3}\d{7}$/,
        phoneMask: '### #######',
        name: 'Greece',
        localizedName: 'Ελλάδα',
        language: 'Ελληνικά',
        languageCode: 'el',
    },
    NL: {
        flagIcon: '🇳🇱',
        dialingCode: '31',
        countryCode: 'NL',
        phoneRegex: /^\d{2}\d{8}$/,
        phoneMask: '## ########',
        name: 'Netherlands',
        localizedName: 'Nederland',
        language: 'Nederlands',
        languageCode: 'nl',
    },
    FR: {
        flagIcon: '🇫🇷',
        dialingCode: '33',
        countryCode: 'FR',
        phoneRegex: /^\d{2}\d{2}\d{2}\d{2}\d{2}$/,
        phoneMask: '## ## ## ## ##',
        name: 'France',
        localizedName: 'France',
        language: 'Français',
        languageCode: 'fr',
    },
    ES: {
        flagIcon: '🇪🇸',
        dialingCode: '34',
        countryCode: 'ES',
        phoneRegex: /^\d{3}\d{2}\d{2}\d{2}$/,
        phoneMask: '### ## ## ##',
        name: 'Spain',
        localizedName: 'España',
        language: 'Español',
        languageCode: 'es',
    },
    IT: {
        flagIcon: '🇮🇹',
        dialingCode: '39',
        countryCode: 'IT',
        phoneRegex: /^\d{2,4}\d{6,8}$/,
        phoneMask: '### #######',
        name: 'Italy',
        localizedName: 'Italia',
        language: 'Italiano',
        languageCode: 'it',
    },
    GB: {
        flagIcon: '🇬🇧',
        dialingCode: '44',
        countryCode: 'GB',
        phoneRegex: /^\d{2}\d{4}\d{4}$/,
        phoneMask: '## #### ####',
        name: 'United Kingdom',
        localizedName: 'United Kingdom',
        language: 'English',
        languageCode: 'en',
    },
    DK: {
        flagIcon: '🇩🇰',
        dialingCode: '45',
        countryCode: 'DK',
        phoneRegex: /^\d{2}\d{2}\d{2}\d{2}$/,
        phoneMask: '## ## ## ##',
        name: 'Denmark',
        localizedName: 'Danmark',
        language: 'Dansk',
        languageCode: 'da',
    },
    SE: {
        flagIcon: '🇸🇪',
        dialingCode: '46',
        countryCode: 'SE',
        phoneRegex: /^\d{2,3}\d{6,8}$/,
        phoneMask: '### #######',
        name: 'Sweden',
        localizedName: 'Sverige',
        language: 'Svenska',
        languageCode: 'sv',
    },
    NO: {
        flagIcon: '🇳🇴',
        dialingCode: '47',
        countryCode: 'NO',
        phoneRegex: /^\d{2}\d{6}$/,
        phoneMask: '## ######',
        name: 'Norway',
        localizedName: 'Norge',
        language: 'Norsk',
        languageCode: 'no',
    },
    PL: {
        flagIcon: '🇵🇱',
        dialingCode: '48',
        countryCode: 'PL',
        phoneRegex: /^\d{2}\d{7,8}$/,
        phoneMask: '## ########',
        name: 'Poland',
        localizedName: 'Polska',
        language: 'Polski',
        languageCode: 'pl',
    },
    PE: {
        flagIcon: '🇵🇪',
        dialingCode: '51',
        countryCode: 'PE',
        phoneRegex: /^\d{2}\d{8}$/,
        phoneMask: '## ########',
        name: 'Peru',
        localizedName: 'Perú',
        language: 'Español',
        languageCode: 'es',
    },
    MX: {
        flagIcon: '🇲🇽',
        dialingCode: '52',
        countryCode: 'MX',
        phoneRegex: /^\d{2}\d{8}$/,
        phoneMask: '## ########',
        name: 'Mexico',
        localizedName: 'México',
        language: 'Español',
        languageCode: 'es',
    },
    AR: {
        flagIcon: '🇦🇷',
        dialingCode: '54',
        countryCode: 'AR',
        phoneRegex: /^\d{2}\d{8}$/,
        phoneMask: '## ########',
        name: 'Argentina',
        localizedName: 'Argentina',
        language: 'Español',
        languageCode: 'es',
    },
    BR: {
        flagIcon: '🇧🇷',
        dialingCode: '55',
        countryCode: 'BR',
        phoneRegex: /^\d{2}\d{8,9}$/,
        phoneMask: '(##) #########',
        name: 'Brazil',
        localizedName: 'Brasil',
        language: 'Português',
        languageCode: 'pt',
    },
    CL: {
        flagIcon: '🇨🇱',
        dialingCode: '56',
        countryCode: 'CL',
        phoneRegex: /^\d{2}\d{8}$/,
        phoneMask: '## ########',
        name: 'Chile',
        localizedName: 'Chile',
        language: 'Español',
        languageCode: 'es',
    },
    CO: {
        flagIcon: '🇨🇴',
        dialingCode: '57',
        countryCode: 'CO',
        phoneRegex: /^\d{2}\d{8}$/,
        phoneMask: '## ########',
        name: 'Colombia',
        localizedName: 'Colombia',
        language: 'Español',
        languageCode: 'es',
    },
    AU: {
        flagIcon: '🇦🇺',
        dialingCode: '61',
        countryCode: 'AU',
        phoneRegex: /^\d{2}\d{4}\d{4}$/,
        phoneMask: '## #### ####',
        name: 'Australia',
        localizedName: 'Australia',
        language: 'English',
        languageCode: 'en',
    },
    NZ: {
        flagIcon: '🇳🇿',
        dialingCode: '64',
        countryCode: 'NZ',
        phoneRegex: /^\d{2}\d{3}\d{4}$/,
        phoneMask: '## ### ####',
        name: 'New Zealand',
        localizedName: 'New Zealand',
        language: 'English/Māori',
        languageCode: 'en/mi',
    },
    TH: {
        flagIcon: '🇹🇭',
        dialingCode: '66',
        countryCode: 'TH',
        phoneRegex: /^\d{2}\d{8}$/,
        phoneMask: '## ########',
        name: 'Thailand',
        localizedName: 'ประเทศไทย',
        language: 'ไทย',
        languageCode: 'th',
    },
    JP: {
        flagIcon: '🇯🇵',
        dialingCode: '81',
        countryCode: 'JP',
        phoneRegex: /^\d{2,4}\d{6,8}$/,
        phoneMask: '### #######',
        name: 'Japan',
        localizedName: '日本',
        language: '日本語',
        languageCode: 'ja',
    },
    KR: {
        flagIcon: '🇰🇷',
        dialingCode: '82',
        countryCode: 'KR',
        phoneRegex: /^\d{2}\d{7,8}$/,
        phoneMask: '## #######',
        name: 'South Korea',
        localizedName: '대한민국',
        language: '한국어',
        languageCode: 'ko',
    },
    VN: {
        flagIcon: '🇻🇳',
        dialingCode: '84',
        countryCode: 'VN',
        phoneRegex: /^\d{2}\d{7,8}$/,
        phoneMask: '## #######',
        name: 'Vietnam',
        localizedName: 'Việt Nam',
        language: 'Tiếng Việt',
        languageCode: 'vi',
    },
    CN: {
        flagIcon: '🇨🇳',
        dialingCode: '86',
        countryCode: 'CN',
        phoneRegex: /^\d{3}\d{7,8}$/,
        phoneMask: '### ########',
        name: 'China',
        localizedName: '中国',
        language: '中文',
        languageCode: 'zh',
    },
    IN: {
        flagIcon: '🇮🇳',
        dialingCode: '91',
        countryCode: 'IN',
        phoneRegex: /^\d{2}\d{8,9}$/,
        phoneMask: '## #########',
        name: 'India',
        localizedName: 'भारत',
        language: 'हिन्दी',
        languageCode: 'hi',
    },
    IE: {
        flagIcon: '🇮🇪',
        dialingCode: '353',
        countryCode: 'IE',
        phoneRegex: /^\d{2}\d{7}$/,
        phoneMask: '## #######',
        name: 'Ireland',
        localizedName: 'Éire',
        language: 'Gaeilge',
        languageCode: 'ga',
    },
    FI: {
        flagIcon: '🇫🇮',
        dialingCode: '358',
        countryCode: 'FI',
        phoneRegex: /^\d{2,3}\d{6,8}$/,
        phoneMask: '### #######',
        name: 'Finland',
        localizedName: 'Suomi',
        language: 'Suomi',
        languageCode: 'fi',
    },
    PG: {
        flagIcon: '🇵🇬',
        dialingCode: '675',
        countryCode: 'PG',
        phoneRegex: /^\d{3}\d{6}$/,
        phoneMask: '### ######',
        name: 'Papua New Guinea',
        localizedName: 'Papua New Guinea',
        language: 'English',
        languageCode: 'en',
    },
    SB: {
        flagIcon: '🇸🇧',
        dialingCode: '677',
        countryCode: 'SB',
        phoneRegex: /^\d{2}\d{5}$/,
        phoneMask: '## #####',
        name: 'Solomon Islands',
        localizedName: 'Solomon Islands',
        language: 'English',
        languageCode: 'en',
    },
    FJ: {
        flagIcon: '🇫🇯',
        dialingCode: '679',
        countryCode: 'FJ',
        phoneRegex: /^\d{3}\d{5}$/,
        phoneMask: '### #####',
        name: 'Fiji',
        localizedName: 'Fiji',
        language: 'English/Fijian',
        languageCode: 'en/fj',
    },
} as const;


// List of all the keys from the Countries object
export type CountryCodes = keyof typeof Countries;

// Same as above but in the object format (like enum)
// This can only be used as a type, not as a value (see below)
export type CountriesEnumType = {
    [k in keyof typeof Countries]: (typeof Countries)[k]['countryCode'];
};

/**
 * Yes this is a hack. No, I'm not proud of it.
 *
 * But if we want to acess all the keys from the Countries object like an enum without needing to manually type them out, this is the only way.
 */
export const CountriesEnum: CountriesEnumType = Object.fromEntries(
    Object.keys(Countries).map(k => [k, k]),
) as CountriesEnumType;
