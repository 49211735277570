import { captureMessage } from '@sentry/react';
import { Elements } from '@stripe/react-stripe-js';
import { SetupIntent, Stripe, loadStripe } from '@stripe/stripe-js';
import { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../api/API.ts';
import { HostedConsentModel } from '../../../types/hosted-consent.types.ts';
import { DataFetching } from '../../animations/DataFetching/DataFetching.tsx';
import Button from '../../atoms/Button/Button.tsx';
import Flex, { Align, FlexDirection, Gap } from '../../atoms/Flex/Flex.tsx';
import Heading from '../../atoms/Heading/Heading.tsx';
import Muted from '../../atoms/Muted/Muted.tsx';
import Notice, { NoticeType } from '../../atoms/Notice/Notice.tsx';
import { PaymentForm } from './PaymentForm/PaymentForm.tsx';

interface AddressPaymentVerificationProps {
    setupIntentClientSecret: string | null;
    consent: HostedConsentModel;
    onVerified: () => void;
    onUnverified: () => void;
    onBack: () => void;
}

function AddressPaymentVerification({
    consent,
    setupIntentClientSecret,
    onVerified,
    onUnverified,
    onBack,
}: AddressPaymentVerificationProps): ReactElement {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const { t } = useTranslation('common');

    const [intent, setIntent] = useState<SetupIntent | null>(null);

    const [stripePromise, setStripePromise] = useState<Stripe | null>(null);
    useEffect(() => {
        if (!stripePromise) {
            loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY)
                .then(stripe => {
                    setStripePromise(stripe);
                })
                .catch(reason => {
                    setError(true);
                    captureMessage(reason);
                });
        }

        // Clear evidences on mount
        API.removeEvidences(consent.id).catch(r => {
            setError(true);
            captureMessage(r);
        });
    }, []);

    useEffect(() => {
        if (setupIntentClientSecret && stripePromise) {
            setLoading(true);
            stripePromise
                .retrieveSetupIntent(setupIntentClientSecret)
                .then(res => {
                    const intent = res.setupIntent;
                    if (intent) {
                        if (intent.status === 'succeeded') {
                            onVerified();
                        } else {
                            onUnverified();
                        }
                    }
                })
                .catch(() => {
                    setError(true);
                    setLoading(false);
                });
        }
    }, [setupIntentClientSecret, stripePromise]);

    useEffect(() => {
        /**
         * Only setup an intent if we don't have a setupIntentClientSecret because that means we're coming
         * back from a redirect and will already have one.
         */
        if (!setupIntentClientSecret) {
            API.axios
                .post(`${import.meta.env.VITE_API}/hosted-consents/hcf/${consent.id}/setup-intent`)
                .then(res => {
                    const intent: SetupIntent = res.data.data;
                    if (intent) {
                        setIntent(intent);
                    }
                })
                .catch(() => {
                    setError(true);
                });
        }
    }, []);

    async function handleBack(): Promise<void> {
        await API.removeEvidences(consent.id);
        onBack();
    }

    if (loading) {
        return <DataFetching text="Verifying address" />;
    }

    if (error) {
        return (
            <Flex flexDirection={FlexDirection.COLUMN} gap={Gap.MD}>
                <Notice type={NoticeType.ERROR}>
                    {t('addressPaymentVerificationScreen.error')}
                </Notice>

                <Button fullWidth outline onClick={handleBack}>
                    {t('common:back')}
                </Button>
            </Flex>
        );
    }

    const stripeOptions = {
        clientSecret: intent?.client_secret ?? '',
        appearance: {
            rules: {
                '.TermsText': {
                    fontSize: '0px',
                    marginTop: '0px',
                },
            },
        },
    };

    if (intent && stripePromise) {
        return (
            <Flex flexDirection={FlexDirection.COLUMN} gap={Gap.LG} align={Align.STRETCH}>
                <Flex flexDirection={FlexDirection.COLUMN} gap={Gap.SM} align={Align.STRETCH}>
                    <Heading>{t('addressPaymentVerificationScreen.heading')}</Heading>
                    <Muted>
                        {t('addressPaymentVerificationScreen.description', {
                            postcode: consent.contextual_data?.postcode,
                        })}
                    </Muted>
                    <Muted>{t('addressPaymentVerificationScreen.disclaimer').toUpperCase()}</Muted>
                </Flex>
                <Elements stripe={stripePromise} options={stripeOptions}>
                    <PaymentForm postcode={consent?.contextual_data?.postcode} />
                </Elements>
                <Button outline fullWidth onClick={handleBack}>
                    {t('common:back')}
                </Button>
            </Flex>
        );
    }

    return <></>;
}

export { AddressPaymentVerification };
