import { ReactElement } from 'react';
import Flex, { Align, FlexDirection, Gap, Justify } from '../../../../atoms/Flex/Flex.tsx';
import { AddressBox } from '../../../../atoms/AddressBox/AddressBox.tsx';
import css from './AddressVerificationMethod.module.scss';
import {
    CpuChipIcon,
    CreditCardIcon,
    DocumentIcon,
    FireIcon,
    HomeIcon,
} from '@heroicons/react/24/outline';
import { HostedConsentModel } from '../../../../../types/hosted-consent.types.ts';
import Heading from '../../../../atoms/Heading/Heading.tsx';
import Muted from '../../../../atoms/Muted/Muted.tsx';
import { useTranslation } from 'react-i18next';

export enum VerificationMethod {
    PAYMENT_METHOD = 'payment-method',
    ELECTRICITY_BILL = 'electricity-bill',
    IN_HOME_DEVICE = 'in-home-device',
}

interface AddressVerificationMethodProps {
    consent: HostedConsentModel;
    onBack: () => void;
    onSelectVerificationMethod: (method: VerificationMethod) => void;
}
function AddressVerificationMethod({
    consent,
    onBack,
    onSelectVerificationMethod,
}: AddressVerificationMethodProps): ReactElement {
    const { t } = useTranslation('uk-flow');
    function handleSelectVerificationMethod(method: VerificationMethod): void {
        onSelectVerificationMethod(method);
    }

    if (!consent.contextual_data) {
        return <></>;
    }

    return (
        <Flex
            className={css.addressVerificationMethod}
            gap={Gap.LG}
            flexDirection={FlexDirection.COLUMN}
            align={Align.STRETCH}>
            <Flex flexDirection={FlexDirection.COLUMN} gap={Gap.SM} align={Align.STRETCH}>
                <AddressBox address={consent.contextual_data.address} onChange={onBack} />
                {consent.contextual_data.found_meter_numbers.electricity && (
                    <div>
                        <Muted className={css.black}>Electricity (MPAN)</Muted>
                        <Flex className={css.mpan} justify={Justify.START} gap={Gap.MD}>
                            <CpuChipIcon className={css.icon} />
                            <span>{consent.contextual_data.found_meter_numbers.electricity}</span>
                        </Flex>
                    </div>
                )}
                {consent.contextual_data.found_meter_numbers.gas && (
                    <div className={css.second}>
                        <Muted className={css.black}>Gas (MPRN)</Muted>
                        <Flex className={css.mpan} justify={Justify.START} gap={Gap.MD}>
                            <FireIcon className={css.icon} />
                            <span>{consent.contextual_data.found_meter_numbers.gas}</span>
                        </Flex>
                    </div>
                )}
            </Flex>
            <div>
                <Heading>{t('residentialSpecificFlow.addressVerificationMethod.heading')}</Heading>
                <Muted>{t('residentialSpecificFlow.addressVerificationMethod.subHeading')}</Muted>
            </div>
            <Flex gap={Gap.SM} flexDirection={FlexDirection.COLUMN} align={Align.STRETCH}>
                <Flex
                    className={css.method}
                    flexDirection={FlexDirection.COLUMN}
                    align={Align.STRETCH}
                    onClick={() => {
                        handleSelectVerificationMethod(VerificationMethod.PAYMENT_METHOD);
                    }}>
                    <Flex className={css.inner} gap={Gap.SM} justify={Justify.START}>
                        <CreditCardIcon className={css.icon} />
                        <span className={css.text}>
                            {t(
                                'residentialSpecificFlow.addressVerificationMethod.verifyByPaymentMethod',
                            )}
                        </span>
                    </Flex>
                    <div className={css.disclaimer}>
                        <Muted>
                            {t('residentialSpecificFlow.addressVerificationMethod.noCharge')}
                        </Muted>
                    </div>
                </Flex>
                <Flex
                    className={css.method}
                    flexDirection={FlexDirection.COLUMN}
                    align={Align.STRETCH}
                    onClick={() => {
                        handleSelectVerificationMethod(VerificationMethod.ELECTRICITY_BILL);
                    }}>
                    <Flex className={css.inner} gap={Gap.SM} justify={Justify.START}>
                        <DocumentIcon className={css.icon} />
                        <span className={css.text}>
                            {t('residentialSpecificFlow.addressVerificationMethod.uploadBill')}
                        </span>
                    </Flex>
                </Flex>
                <Flex
                    className={`${css.method} ${css.disabled}`}
                    flexDirection={FlexDirection.COLUMN}
                    align={Align.STRETCH}>
                    <Flex className={css.inner} gap={Gap.SM} justify={Justify.START}>
                        <HomeIcon className={css.icon} />
                        <span className={css.text}>
                            {t('residentialSpecificFlow.addressVerificationMethod.inHomeDevice')}
                        </span>
                        <span className={css.comingSoon}>{t('common:comingSoon')}</span>
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
}

export { AddressVerificationMethod };
