import { observer } from 'mobx-react-lite';
import { ChangeEvent, ReactElement, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../../../api/API.ts';
import AppContext from '../../../../../store/AppContext.ts';
import { ConsentMethod, HostedConsentModel } from '../../../../../types/hosted-consent.types.ts';
import { validateEmail } from '../../../../../utils/validateEmail.ts';
import Button from '../../../../atoms/Button/Button.tsx';
import { ContentToggle } from '../../../../atoms/ContentToggle/ContentToggle.tsx';
import Flex, { Align, FlexDirection, Gap } from '../../../../atoms/Flex/Flex.tsx';
import Heading from '../../../../atoms/Heading/Heading.tsx';
import Loading from '../../../../atoms/Loading/Loading.tsx';
import TextInput from '../../../../atoms/TextInput/TextInput.tsx';
import Card from '../../../../molecules/Card/Card.tsx';
import ConsentSteps from '../../../../molecules/ConsentSteps/ConsentSteps.tsx';
import PlatformTermsCheckbox from '../../../../organisms/PlatformTermsCheckbox/PlatformTermsCheckbox.tsx';
import { GBResidentialSpecificConsentSteps } from '../../gb.residential.specific.tsx';
import css from './ProvideConsent.module.scss';

interface ProvideConsentProps {
    consent: HostedConsentModel;
    onNext: () => void;
}

function ProvideConsent({ consent, onNext }: ProvideConsentProps): ReactElement {
    const { t } = useTranslation('uk-flow');
    const { ConsentStore } = useContext(AppContext);

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(consent.consentee_email ?? '');
    const [name, setName] = useState(consent.consentee_full_name ?? '');

    const [conditionsToggle, setConditionsToggle] = useState(false);

    function handleChangeEmail(e: ChangeEvent<HTMLInputElement>): void {
        setEmail(e.target.value);
    }

    function handleChangeName(e: ChangeEvent<HTMLInputElement>): void {
        setName(e.target.value);
    }

    async function handleSubmit(): Promise<void> {
        setLoading(true);
        const res = await API.updateConsent(consent.id, {
            consentee_email: email,
            consentee_full_name: name,
            consent_method: ConsentMethod.DIGITAL_CONSENT,
        });
        ConsentStore.setConsent(res.data.data);
        await API.approveConsent(consent.id);
        onNext();
    }

    function handleConditionsToggle(): void {
        setConditionsToggle(!conditionsToggle);
    }

    if (loading) {
        return <Loading />;
    }

    return (
        <Card className={css.provideConsent} account={consent.account}>
            <Flex gap={Gap.LG} flexDirection={FlexDirection.COLUMN} align={Align.STRETCH}>
                <ConsentSteps
                    steps={GBResidentialSpecificConsentSteps}
                    current={GBResidentialSpecificConsentSteps[2]}
                />
                <Heading>{t('provideConsentScreen.heading')}</Heading>
                <TextInput
                    value={name}
                    onChange={handleChangeName}
                    label={t('provideConsentScreen.namePlaceholder')}
                />
                <TextInput
                    value={email}
                    onChange={handleChangeEmail}
                    label={t('provideConsentScreen.emailPlaceholder')}
                />
                <PlatformTermsCheckbox>
                    {t('common:iAccept')}{' '}
                    <span className={css.click} onClick={handleConditionsToggle}>
                        {t('common:consentConditions')}
                    </span>
                </PlatformTermsCheckbox>
                <ContentToggle
                    title={t('common:consentConditions')}
                    open={conditionsToggle}
                    onToggled={open => {
                        setConditionsToggle(open);
                    }}>
                    <Flex flexDirection={FlexDirection.COLUMN} gap={Gap.SM} align={Align.STRETCH}>
                        <span>
                            {t('provideConsentScreen.termsText.line1', {
                                account: consent.account.name,
                            })}
                        </span>
                        <span>{t('provideConsentScreen.termsText.line2')}</span>
                        <span>
                            {t('provideConsentScreen.termsText.line3', {
                                account: consent.account.name,
                            })}
                        </span>
                        <span>
                            {t('provideConsentScreen.termsText.line4', {
                                expiryDate: new Date(consent.expiry).toDateString(),
                            })}
                        </span>
                    </Flex>
                </ContentToggle>
                <Button
                    fullWidth
                    disabled={
                        !ConsentStore.termsChecked || !validateEmail(email) || name.length === 0
                    }
                    onClick={handleSubmit}>
                    {t('common:next')}
                </Button>
            </Flex>
        </Card>
    );
}

export default observer(ProvideConsent);
