import { ReactElement, useContext, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { getI18n } from 'react-i18next';
import css from './LanguageSelection.module.scss';
import AppContext from '../../../store/AppContext';

export enum Languages {
    EN = 'en',
    ES = 'es',
    FR = 'fr',
    NL = 'nl',
}

export const AvailableLanguages = {
    [Languages.EN]: {
        name: 'English',
        flag: '🇬🇧',
    },
    [Languages.ES]: {
        name: 'Español',
        flag: '🇪🇸',
    },
    [Languages.FR]: {
        name: 'Français',
        flag: '🇫🇷',
    },
    [Languages.NL]: {
        name: 'Nederlands',
        flag: '🇳🇱',
    },
} as const;

export interface LanguageSelectionProps {
    availableLanguages?: Languages[];
    showFlag?: boolean;
    className?: string;
}

function LanguageSelection({
    availableLanguages,
    showFlag = true,
    className,
}: LanguageSelectionProps): ReactElement {
    const { LanguageStore } = useContext(AppContext);

    // If no available languages are provided,
    // use the available languages from the store
    // if store is not set use English as default
    const matchedLanguages = availableLanguages?.length
        ? availableLanguages
        : LanguageStore.availableLanguages.length
          ? LanguageStore.availableLanguages
          : [Languages.EN];
    const i18nInstance = getI18n();

    useEffect(() => {
        if (LanguageStore.currentlySelectedLanguage) {
            i18nInstance.changeLanguage(LanguageStore.currentlySelectedLanguage).catch(e => {
                console.error('Could not change language', e);
            });
        }
    }, [LanguageStore.currentlySelectedLanguage]);

    useEffect(() => {
        if (!LanguageStore.currentlySelectedLanguage) {
            LanguageStore.setCurrentlySelectedLanguage(LanguageStore.defaultLanguage);
        }
    }, []);

    // If there is only one language, don't render the select
    // as there is no need to change the language
    if (matchedLanguages.length === 1) {
        return <></>;
    }

    return (
        <select
            className={`${css.selectInput} ${className}`}
            onChange={event => {
                // setCurrentLanguage(event.target.value);
                LanguageStore.setCurrentlySelectedLanguage(event.target.value as Languages);
            }}
            value={LanguageStore.currentlySelectedLanguage ?? LanguageStore.defaultLanguage}>
            {matchedLanguages.map(lang => {
                return (
                    <option key={lang} value={lang}>
                        {showFlag
                            ? `${AvailableLanguages[lang].flag} ${AvailableLanguages[lang].name}`
                            : AvailableLanguages[lang].name}
                    </option>
                );
            })}
        </select>
    );
}

export default observer(LanguageSelection);
