import { type ReactElement } from 'react';
import Flex, { FlexDirection, Gap } from '../../atoms/Flex/Flex.tsx';
import FadeIn from '../../animations/FadeIn/FadeIn.tsx';
import css from './DeadEnd.module.scss';
function DeadEnd(): ReactElement {
    return (
        <Flex className={css.deadEnd} flexDirection={FlexDirection.COLUMN}>
            <FadeIn>
                <Flex flexDirection={FlexDirection.COLUMN} gap={Gap.MD}>
                    <img className={css.logo} src="/logo-full.svg" />
                    <span>v{APP_VERSION}</span>
                </Flex>
            </FadeIn>
        </Flex>
    );
}

export default DeadEnd;
