import { type PropsWithChildren, type ReactElement } from 'react';
import { motion } from 'framer-motion';

export interface FadeInPropsType {
    duration?: number;
    className?: string;
}

function FadeIn({
    duration = 0.6,
    children,
    className = '',
}: PropsWithChildren<FadeInPropsType>): ReactElement {
    return (
        <motion.div
            className={className}
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration }}>
            {children}
        </motion.div>
    );
}

export default FadeIn;
