import { ReactElement, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ConsentMethod, HostedConsentModel } from '../../../../../types/hosted-consent.types.ts';
import TextInput from '../../../../atoms/TextInput/TextInput.tsx';
import { useTranslation } from 'react-i18next';
import Flex, { Align, FlexDirection, Gap } from '../../../../atoms/Flex/Flex.tsx';
import { PostcoderSearchResult } from '../../../../../types/common.types.ts';
import { EANDataAPIMeteringPoint } from '../../../../organisms/EANSearch/EANSearch.tsx';
import { MeterNumberBox } from '../../../../atoms/MeterNumberBox/MeterNumberBox.tsx';
import AppContext from '../../../../../store/AppContext.ts';
import Button from '../../../../atoms/Button/Button.tsx';
import API from '../../../../../api/API.ts';
import Loading from '../../../../atoms/Loading/Loading.tsx';
import Notice, { NoticeType } from '../../../../atoms/Notice/Notice.tsx';
import { BoltIcon } from '@heroicons/react/24/solid';
import { FireIcon } from '@heroicons/react/24/outline';

interface ConsentDetailsCollectorProps {
    consent: HostedConsentModel;
    onSaved: () => void;
    onBack: () => void;
    address: PostcoderSearchResult | null;
    meteringPoints: EANDataAPIMeteringPoint[] | null;
}

function ConsentDetailsCollector({
    consent,
    onSaved,
    onBack,
    address,
    meteringPoints,
}: ConsentDetailsCollectorProps): ReactElement {
    const { t } = useTranslation('nl-flow');
    const { ConsentStore } = useContext(AppContext);

    const [name, setName] = useState(consent.consentee_full_name ?? '');
    const [email, setEmail] = useState(consent.consentee_email ?? '');

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<boolean>(false);

    function handleBack(): void {
        onBack();
    }

    async function handleSave(): Promise<void> {
        setError(false);
        setLoading(true);
        try {
            const res = await API.updateConsent(consent.id, {
                meter_numbers: meteringPoints ? meteringPoints.map(mp => mp.ean) : [],
                consentee_full_name: name,
                consentee_email: email,
                consent_method: ConsentMethod.DIGITAL_CONSENT,
                contextual_data: {
                    ...consent.contextual_data,
                    address: address?.summaryline,
                    address_identifier: address?.number,
                    postcode: address?.postcode,
                    found_meter_numbers: meteringPoints,
                },
            });
            ConsentStore.setConsent(res.data.data);
            onSaved();
        } catch (e) {
            setError(true);
            setLoading(false);
        }
    }

    if (loading) {
        return <Loading />;
    }

    return (
        <Flex flexDirection={FlexDirection.COLUMN} gap={Gap.MD} align={Align.STRETCH}>
            {error && (
                <Notice type={NoticeType.ERROR}>
                    There was an error saving your consent information, please contact your software
                    provider.
                </Notice>
            )}
            <TextInput
                value={name}
                onChange={e => {
                    setName(e.target.value);
                }}
                label={t('residentialFlow.provideConsent.detailsCollectionName')}
            />
            <TextInput
                value={email}
                onChange={e => {
                    setEmail(e.target.value);
                }}
                label={t('residentialFlow.provideConsent.detailsCollectionEmail')}
            />
            <Flex gap={Gap.MD}>
                {address?.number && (
                    <TextInput
                        value={address?.number}
                        onChange={() => {}}
                        disabled
                        label={t('residentialFlow.provideConsent.detailsCollectionHouseNumber')}
                    />
                )}
                {address?.postcode && (
                    <TextInput
                        value={address?.postcode}
                        onChange={() => {}}
                        disabled
                        label={t('residentialFlow.provideConsent.detailsCollectionPostcode')}
                    />
                )}
            </Flex>
            {meteringPoints && meteringPoints.length > 0 && (
                <Flex gap={Gap.MD} flexDirection={FlexDirection.COLUMN} align={Align.STRETCH}>
                    <span>{t('residentialFlow.provideConsent.detailsCollectionEANsFound')}</span>
                    {meteringPoints.map(mp => {
                        return (
                            <MeterNumberBox
                                key={mp.ean}
                                meterNumber={mp.ean}
                                icon={mp.product === 'ELK' ? <BoltIcon /> : <FireIcon />}
                            />
                        );
                    })}
                </Flex>
            )}
            <Flex gap={Gap.MD}>
                <Button outline onClick={handleBack}>
                    {t('common:back')}
                </Button>
                <Button disabled={name.length === 0 || email.length === 0} onClick={handleSave}>
                    {t('common:next')}
                </Button>
            </Flex>
        </Flex>
    );
}

export default observer(ConsentDetailsCollector);
