import { observer } from 'mobx-react-lite';
import { useContext, useState, type ReactElement } from 'react';
import { HostedConsentModel } from '../../../types/hosted-consent.types.ts';
import { ScreenTransition } from '../../animations/ScreenTransition/ScreenTransition.tsx';
import { type ConsentStep } from '../../molecules/ConsentSteps/ConsentSteps.tsx';
import StartScreen from '../../molecules/StartScreen/StartScreen.tsx';
import { Completed } from '../../molecules/ConsentCompleted/Completed.tsx';
import EANCollection from './flow-specific/shared/EANCollection/EANCollection.tsx';
import OperatorSelection from './flow-specific/shared/OperatorSelection/OperatorSelection.tsx';
import AppContext from '../../../store/AppContext.ts';
import { Languages } from '../../atoms/LanguageSelection/LanguageSelection.tsx';
import { useTranslation } from 'react-i18next';

enum ConsentFlowScreens {
    START = 'start',
    EAN_COLLECTION = 'ean-collection',
    OPERATOR_SELECTION = 'operator-selector',
    COMPLETED = 'completed',
    COMPLETED_EXTRA_STEPS = 'completed-with-extra-steps',
}

export interface NLCommercialSpecificProps {
    consent: HostedConsentModel;
}

export const NLCommercialSpecificConsentSteps: ConsentStep[] = [
    { text: 'Get EAN Code', value: 'ean' },
    { text: 'Connect Operator', value: 'operator' },
];

function NLCommercialSpecific({ consent }: NLCommercialSpecificProps): ReactElement {
    const { t } = useTranslation('nl-flow');
    // This has to be done like this because we are using the same component
    // on the operator selection and the EANCollection screen
    NLCommercialSpecificConsentSteps[0].text = t('common.step1');
    NLCommercialSpecificConsentSteps[1].text = t('common.step2');
    const [screen, setScreen] = useState<ConsentFlowScreens>(ConsentFlowScreens.EAN_COLLECTION);
    const { LanguageStore } = useContext(AppContext);
    LanguageStore.setAvailableLanguages([Languages.NL, Languages.EN]);
    LanguageStore.setDefaultLanguage(Languages.NL);

    function renderScreen(): ReactElement {
        if (consent != null) {
            switch (screen) {
                case ConsentFlowScreens.START:
                    return (
                        <StartScreen
                            logo={consent.account.logo}
                            logoMetadata={
                                consent.account.logo_metadata ? consent.account.logo_metadata : null
                            }
                            partner={consent.account.name}
                            onNext={() => {
                                setScreen(ConsentFlowScreens.EAN_COLLECTION);
                            }}
                        />
                    );
                case ConsentFlowScreens.EAN_COLLECTION:
                    return (
                        <EANCollection
                            consent={consent}
                            onNext={() => {
                                setScreen(ConsentFlowScreens.OPERATOR_SELECTION);
                            }}
                        />
                    );
                case ConsentFlowScreens.OPERATOR_SELECTION:
                    return (
                        <OperatorSelection
                            consent={consent}
                            onNext={(hasExtraSteps: boolean) => {
                                if (hasExtraSteps) {
                                    setScreen(ConsentFlowScreens.COMPLETED_EXTRA_STEPS);
                                    return;
                                }
                                setScreen(ConsentFlowScreens.COMPLETED);
                            }}
                        />
                    );
                case ConsentFlowScreens.COMPLETED:
                    return <Completed consent={consent} />;
                case ConsentFlowScreens.COMPLETED_EXTRA_STEPS:
                    return <Completed consent={consent} hasExtraSteps={true} />;
            }
        }
        return <></>;
    }
    return <ScreenTransition screen={screen}>{renderScreen()}</ScreenTransition>;
}

export default observer(NLCommercialSpecific);
