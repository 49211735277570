import { ReactElement, useContext, useState } from 'react';
import { DataFetching } from '../../animations/DataFetching/DataFetching.tsx';
import { useTranslation } from 'react-i18next';
import AppContext from '../../../store/AppContext.ts';
import { ConsentFlowType } from '../../../types/hosted-consent.types.ts';
import { DocusealForm } from '@docuseal/react';
import css from './DocusealSignatureCapture.module.scss';
import API from '../../../api/API.ts';

interface DocusealSignatureCaptureProps {
    flow: ConsentFlowType;
    onCaptured: (details: any) => void;
}

function DocusealSignatureCapture({
    flow,
    onCaptured,
}: DocusealSignatureCaptureProps): ReactElement {
    const [loading, setLoading] = useState(false);

    const { ConsentStore } = useContext(AppContext);

    const { t } = useTranslation(`${flow.split('.')[0]}-flow`); // Use the country code from the start of the consent flow string.

    async function handleComplete(details: any): Promise<void> {
        const consent = ConsentStore.consent;
        setLoading(true);
        if (consent) {
            try {
                const res = await API.updateConsent(consent.id, {
                    contextual_data: {
                        ...consent.contextual_data,
                        docuseal: details,
                    },
                });
                ConsentStore.setConsent(res.data.data);
                onCaptured(details);
            } catch (e) {}
        }
        setLoading(false);
    }

    function embed() {
        const consent = ConsentStore.consent;
        if (consent) {
            switch (flow) {
                case ConsentFlowType.NL_RESIDENTIAL_SPECIFIC:
                    // eslint-disable-next-line no-case-declarations
                    const documentData: any = {
                        name: consent.consentee_full_name,
                        street_address: consent.contextual_data?.address,
                        postal_code: consent.contextual_data?.postcode,
                        place: consent.contextual_data?.address_identifier,
                        date: new Date(),
                        account: consent.account.name,
                    };
                    consent.contextual_data?.found_meter_numbers.forEach((mp: any, i: number) => {
                        documentData[`ean_${i + 1}_type`] = mp.product;
                        documentData[`ean_${i + 1}_number`] = mp.ean;
                        documentData[`ean_${i + 1}_address`] = documentData.street_address;
                    });
                    return (
                        <DocusealForm
                            src="https://docuseal.co/d/eWgUiaTzQWH9nk"
                            email={consent.consentee_email}
                            role="consentee"
                            sendCopyEmail={false}
                            values={documentData}
                            minimize
                            onComplete={handleComplete}
                            withDownloadButton={false}
                        />
                    );
            }
        }
    }

    if (loading) {
        return <DataFetching text={t('residentialFlow.consentSignOff.preparingDocument')} />;
    }

    return <div className={css.docuseal}>{embed()}</div>;
}

export default DocusealSignatureCapture;
