import Flex, { Gap, Justify } from '../../atoms/Flex/Flex.tsx';
import { observer } from 'mobx-react-lite';
import { PropsWithChildren, type ReactElement, useContext } from 'react';
import AppContext from '../../../store/AppContext.ts';
import css from './PlatformTermsCheckbox.module.scss';
import { useTranslation } from 'react-i18next';

interface PlatformTermsCheckboxProps {
    noContent?: boolean;
}

function PlatformTermsCheckbox({
    children,
    noContent,
}: PropsWithChildren<PlatformTermsCheckboxProps>): ReactElement {
    const { ConsentStore } = useContext(AppContext);
    const { t } = useTranslation();

    function handleChangeTerms(): void {
        ConsentStore.setTermsChecked(!ConsentStore.termsChecked);
    }

    return (
        <Flex className={css.platformTermsCheckbox} gap={Gap.MD} justify={Justify.START}>
            <input
                type="checkbox"
                checked={ConsentStore.termsChecked}
                onChange={handleChangeTerms}
            />
            {!noContent && (
                <>{children ? <span>{children}</span> : <span>{t('common:termsCheckbox')}</span>}</>
            )}
        </Flex>
    );
}

export default observer(PlatformTermsCheckbox);
