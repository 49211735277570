import { type ReactElement, useEffect, useState, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import StartScreen from '../../molecules/StartScreen/StartScreen.tsx';
import BlanketDescriptionScreen from '../../molecules/BlanketDescriptionScreen/BlanketDescriptionScreen.tsx';
import ConsentMethodScreen from './flow-specific/ConsentMethodScreen/ConsentMethodScreen.tsx';
import LoaConsentScreen from './flow-specific/LoaConsentScreen/LoaConsentScreen.tsx';
import DigitalConsent from './flow-specific/DigitalConsent/DigitalConsent.tsx';
import ThirdPartyConsentScreen from './flow-specific/ThirdPartyConsentScreen/ThirdPartyConsentScreen.tsx';
import { useSearch } from 'wouter';
import EmailVerificationSuccess from '../../molecules/EmailVerificationSuccess/EmailVerificationSuccess.tsx';
import Card from '../../molecules/Card/Card.tsx';
import { ConsentMethod, HostedConsentModel } from '../../../types/hosted-consent.types.ts';
import { ScreenTransition } from '../../animations/ScreenTransition/ScreenTransition.tsx';
import AppContext from '../../../store/AppContext.ts';
import { Languages } from '../../atoms/LanguageSelection/LanguageSelection.tsx';

enum ConsentFlowScreens {
    START = 'start',
    BLANKET_DESCRIPTION = 'blanket_description',
    CONSENT_METHOD = 'consent_method',
    LOA_CONSENT = 'loa_submission',
    DIGITAL_CONSENT = 'digital_consent',
    THIRD_PARTY_CONSENT = 'third_party_consent',
    COMPLETED = 'completed',
}

export interface GBCommercialBlanketProps {
    consent: HostedConsentModel;
    evidenceDocument: string | null;
    termsChecked: boolean;
}

function GBCommercialBlanket({
    consent,
    evidenceDocument,
    termsChecked,
}: GBCommercialBlanketProps): ReactElement {
    const [screen, setScreen] = useState<ConsentFlowScreens>(ConsentFlowScreens.START);
    const { LanguageStore } = useContext(AppContext);
    LanguageStore.setAvailableLanguages([Languages.EN]);

    const search = useSearch();
    const searchParams = new URLSearchParams(search);
    const isThirdParty = searchParams.get('third-party') === 'true';
    const code = searchParams.get('code');

    useEffect(() => {
        if (isThirdParty) {
            setScreen(ConsentFlowScreens.DIGITAL_CONSENT);
        }
    }, []);

    function renderScreen(): ReactElement {
        if (consent != null) {
            switch (screen) {
                case ConsentFlowScreens.START:
                    return (
                        <StartScreen
                            logo={consent.account.logo}
                            logoMetadata={
                                consent.account.logo_metadata ? consent.account.logo_metadata : null
                            }
                            partner={consent.account.name}
                            onNext={() => {
                                if (consent.flow_type.includes('blanket')) {
                                    setScreen(ConsentFlowScreens.BLANKET_DESCRIPTION);
                                } else {
                                    setScreen(ConsentFlowScreens.CONSENT_METHOD);
                                }
                            }}
                        />
                    );
                case ConsentFlowScreens.BLANKET_DESCRIPTION:
                    return (
                        <BlanketDescriptionScreen
                            account={consent.account}
                            partner={consent.account.name}
                            onNext={() => {
                                if (consent.consent_method != null) {
                                    switch (consent.consent_method) {
                                        case ConsentMethod.DIGITAL_CONSENT:
                                            setScreen(ConsentFlowScreens.DIGITAL_CONSENT);
                                            break;
                                        case ConsentMethod.LETTER_OF_AUTHORITY:
                                            setScreen(ConsentFlowScreens.LOA_CONSENT);
                                            break;
                                        case ConsentMethod.THIRD_PARTY_DIGITAL_CONSENT:
                                            setScreen(ConsentFlowScreens.THIRD_PARTY_CONSENT);
                                            break;
                                        default:
                                            setScreen(ConsentFlowScreens.CONSENT_METHOD);
                                            break;
                                    }
                                } else {
                                    setScreen(ConsentFlowScreens.CONSENT_METHOD);
                                }
                            }}
                        />
                    );
                case ConsentFlowScreens.CONSENT_METHOD:
                    return (
                        <ConsentMethodScreen
                            account={consent.account}
                            blanketConsent={consent.flow_type.includes('blanket')}
                            partner={consent.account.name}
                            consent={consent}
                            onNext={(method: ConsentMethod | null) => {
                                switch (method) {
                                    case ConsentMethod.DIGITAL_CONSENT:
                                        setScreen(ConsentFlowScreens.DIGITAL_CONSENT);
                                        break;
                                    case ConsentMethod.LETTER_OF_AUTHORITY:
                                        setScreen(ConsentFlowScreens.LOA_CONSENT);
                                        break;
                                    case ConsentMethod.THIRD_PARTY_DIGITAL_CONSENT:
                                        setScreen(ConsentFlowScreens.THIRD_PARTY_CONSENT);
                                }
                            }}
                        />
                    );
                case ConsentFlowScreens.LOA_CONSENT:
                    return (
                        <LoaConsentScreen
                            account={consent.account}
                            consent={consent}
                            evidenceDocument={evidenceDocument}
                            termsChecked={termsChecked}
                            onNext={() => {
                                setScreen(ConsentFlowScreens.COMPLETED);
                            }}
                            onBack={() => {
                                setScreen(ConsentFlowScreens.CONSENT_METHOD);
                            }}
                        />
                    );
                case ConsentFlowScreens.DIGITAL_CONSENT:
                    return (
                        <DigitalConsent
                            account={consent.account}
                            consent={consent}
                            termsChecked={termsChecked}
                            isThirdParty={isThirdParty}
                            code={code}
                            onNext={() => {
                                setScreen(ConsentFlowScreens.COMPLETED);
                            }}
                            onBack={() => {
                                setScreen(ConsentFlowScreens.CONSENT_METHOD);
                            }}
                        />
                    );
                case ConsentFlowScreens.THIRD_PARTY_CONSENT:
                    return (
                        <ThirdPartyConsentScreen
                            account={consent.account}
                            consent={consent}
                            partner={consent.account.name}
                            onBack={() => {
                                setScreen(ConsentFlowScreens.CONSENT_METHOD);
                            }}
                        />
                    );
                case ConsentFlowScreens.COMPLETED:
                    return (
                        <Card account={consent.account}>
                            <EmailVerificationSuccess
                                consent={consent}
                                isThirdParty={isThirdParty}
                            />
                        </Card>
                    );
            }
        }
        return <></>;
    }

    return <ScreenTransition screen={screen}>{renderScreen()}</ScreenTransition>;
}

export default observer(GBCommercialBlanket);
