import { ChangeEvent, useContext, useState, type ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import API from '../../../../../../api/API.ts';
import AppContext from '../../../../../../store/AppContext.ts';
import { HostedConsentModel } from '../../../../../../types/hosted-consent.types.ts';
import { ScreenTransition } from '../../../../../animations/ScreenTransition/ScreenTransition.tsx';
import Button from '../../../../../atoms/Button/Button.tsx';
import { ErrorNotice } from '../../../../../atoms/ErrorNotice/ErrorNotice.tsx';
import Flex, { Align, FlexDirection, Gap } from '../../../../../atoms/Flex/Flex.tsx';
import Heading from '../../../../../atoms/Heading/Heading.tsx';
import TextInput from '../../../../../atoms/TextInput/TextInput.tsx';
import Card from '../../../../../molecules/Card/Card.tsx';
import ConsentSteps from '../../../../../molecules/ConsentSteps/ConsentSteps.tsx';
import { FlowSteps } from '../../../es.residential.specific.tsx';
import css from './PersonInfo.module.scss';
import { AddressBox } from '../../../../../atoms/AddressBox/AddressBox.tsx';

export enum Steps {
    INFO_COLLECTION = 'info_collection',
    INFO_CONFIRM = 'info_confirm',
}

interface PersonInfoProps {
    consent: HostedConsentModel;
    onNext: () => void;
}

interface ESPersonInfo {
    full_name?: string;
    dni?: string;
}

function PersonInfoCollection({ consent, onNext }: PersonInfoProps): ReactElement {
    // #region State
    const [error, setError] = useState<null | string>(null);
    const [step, setStep] = useState(Steps.INFO_COLLECTION);
    const { t } = useTranslation('es-flow');

    /**
     * Stuff the user provides or clicks on.
     */
    const [personInfo, setPersonInfo] = useState<ESPersonInfo>({});

    const { ConsentStore } = useContext(AppContext);
    // #endregion

    // #region Handlers
    function handleInput(key: keyof ESPersonInfo) {
        return ({ target: { value } }: ChangeEvent<HTMLInputElement>): void => {
            let parsedValue = value;
            let error = null;

            if (key === 'dni') {
                parsedValue = value.toUpperCase();
                if (parsedValue.length > 9) {
                    return;
                } else if (
                    parsedValue.length > 0 &&
                    !/^[0-9]{8}[A-Z]{1}$/.test(parsedValue.toUpperCase())
                ) {
                    error = t('es-flow:errors.dniFormat');
                }
            }

            setError(error);
            setPersonInfo({ ...personInfo, [key]: parsedValue });
        };
    }
    async function handleFillPersonInfo(): Promise<void> {
        try {
            if (!personInfo?.dni || !personInfo?.full_name) {
                setError(t('es-flow:residentialFlow:errors.missingInfo'));
                return;
            }
            const { data: apiResponse } = await API.updateAuthorizationData(consent.id, {
                authorization_data: {
                    dni: personInfo.dni,
                    full_name: personInfo.full_name,
                },
            });
            ConsentStore.setConsent({
                ...consent,
                authorization_data: apiResponse.authorization_data,
            });
            setStep(Steps.INFO_CONFIRM);
        } catch (e: any) {
            setError(e);
        }
    }

    function handleConfirm(): void {
        onNext();
    }

    function handleBack(): void {
        setStep(Steps.INFO_COLLECTION);
    }

    // #endregion

    function renderStep(): ReactElement {
        switch (step) {
            case Steps.INFO_COLLECTION:
                return (
                    <Flex
                        className={css.personInfo}
                        gap={Gap.MD}
                        flexDirection={FlexDirection.COLUMN}
                        align={Align.STRETCH}>
                        <Heading>{t('es-flow:residentialFlow:collectionStep.heading')}</Heading>
                        {error != null && <ErrorNotice errors={error} />}
                        <div className={css.infoBox}>
                            <TextInput
                                label={t('common:fullName')}
                                type="text"
                                className={css.fullNameField}
                                onChange={handleInput('full_name')}
                                value={personInfo?.full_name ?? ''}
                            />
                            <TextInput
                                label={t('es-flow:residentialFlow:collectionStep.dniLabel')}
                                type="text"
                                className={css.dniField}
                                onChange={handleInput('dni')}
                                value={personInfo?.dni ?? ''}
                            />
                            <Button
                                fullWidth={true}
                                onClick={handleFillPersonInfo}
                                disabled={!personInfo.dni || !personInfo.full_name || !!error}>
                                {t('common:next')}
                            </Button>
                        </div>
                    </Flex>
                );
            case Steps.INFO_CONFIRM:
                return (
                    <Flex
                        className={css.infoConfirm}
                        gap={Gap.MD}
                        flexDirection={FlexDirection.COLUMN}
                        align={Align.STRETCH}>
                        <Heading>{t('es-flow:residentialFlow:confirmStep.heading')}</Heading>
                        {error != null && <ErrorNotice errors={error} />}
                        <div className={css.infoBox}>
                            <AddressBox
                                address={`${personInfo?.full_name}, DNI: ${personInfo?.dni}`}
                            />
                        </div>
                        <Flex flexDirection={FlexDirection.ROW} gap={Gap.SM}>
                            <Button outline={true} fullWidth={true} onClick={handleBack}>
                                {t('common:back')}
                            </Button>
                            <Button fullWidth={true} onClick={handleConfirm}>
                                {t('common:confirm')}
                            </Button>
                        </Flex>
                    </Flex>
                );
            default:
                return <></>;
        }
    }

    return (
        <Card account={consent.account}>
            <Flex gap={Gap.LG} flexDirection={FlexDirection.COLUMN} align={Align.STRETCH}>
                <ConsentSteps steps={FlowSteps} current={FlowSteps[0]} />
                <ScreenTransition screen={step}>{renderStep()}</ScreenTransition>
            </Flex>
        </Card>
    );
}

export default PersonInfoCollection;
